<template>
  <div
    class="d-flex justify-content-center align-items-center"
    v-if="getUserDetail.role == 'Accounting Officer' ? false : true"
  >
    <button
      class="btn-lost"
      @click="ConfirmLost"
      v-if="this.params.value.is_lost === 'report'"
    >
      {{ $t("btn.conf_lost") }}
    </button>
    <button
      class="btn-found"
      @click="ConfirmFound"
      v-if="this.params.value.is_lost === 'report_found'"
    >
      {{ $t("btn.conf_found") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
    }),
  },
  mounted() {
    // console.log("params", this.params.value);
  },
  methods: {
    ConfirmLost() {
      this.params.value.reportType = "lost";
      console.log(this.params.value);
      this.$parent.$parent.$parent.openConfirmLost(this.params.value);
    },
    ConfirmFound() {
      this.params.value.reportType = "found";
      this.$parent.$parent.$parent.openConfirmFound(this.params.value);
    },
  },
};
</script>

<style scoped>
.btn-lost {
  padding: 0 1em;
  color: #ed1616;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ed1616;
}
.btn-found {
  padding: 0 1em;
  color: #1e9400;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #1e9400;
}
</style>
