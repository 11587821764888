<template>
  <v-lazy-image
    class="banner"
    :src="image"
    alt=""
    :src-placeholder="holderimg"
  />
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage,
  },
  props: {
    image: {
      type: String,
    },
    holderimg: { type: String },
  },
};
</script>
<style lang="scss">
.colorGrey {
  background-color: #d2e2f8 !important;
}

button.sty-button {
  width: 120px;
  height: 40px;
  margin-top: 5px;
  border-radius: 15px;
  border-color: transparent;
  background: transparent linear-gradient(90deg, #0069da 0%, #004ea1 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  font-size: 20px;
}

img.asset-image {
  width: 80px;
  height: auto;
}

button.see-more {
  width: 36px;
  height: auto;
  padding-left: 20px;
  border-color: transparent;
  background: #fff;
  img {
    width: 12px;
    height: auto;
    opacity: 0.7;
  }
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

// .v-lazy-image {
//   opacity: 0.5;
//   transition: opacity 2s;
// }
// .v-lazy-image-loaded {
//   opacity: 1;
// }

.lazy-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}
</style>
