import HomeTH from "./lang/th/home.json";
import AssetTH from "./lang/th/asset.json";
import btnTH from "./lang/th/btn.json";
import countTH from "./lang/th/count.json";
import checkitemTH from "./lang/th/checkitem.json";
import dashboardTH from "./lang/th/dashboard.json";
import detailasTH from "./lang/th/detailasset.json";
import dialogTH from "./lang/th/dialog.json";
import docregasTH from "./lang/th/docregas.json";
import filterTH from "./lang/th/filter.json";
import historyTH from "./lang/th/history.json";
import profileTH from "./lang/th/profile.json";
import passwordTH from "./lang/th/password.json";
import scanTH from "./lang/th/scan.json";
import loginTH from "./lang/th/login.json";
import selectcomTH from "./lang/th/selectcompany.json";
import registerTH from "./lang/th/register.json";
import registeritemTH from "./lang/th/registeritem.json";
import approveTH from "./lang/th/approve.json";
import adddateTH from "./lang/th/adddate.json";
import adminTH from "./lang/th/admin.json";
import imgoneassetTH from "./lang/th/imgoneasset.json";
import importTH from "./lang/th/import.json";
// import maintenanceTH from "./lang/th/maintenance.json";
import categoryTH from "./lang/th/category.json";
import departmentTH from "./lang/th/department.json";
import zoneTH from "./lang/th/zone.json";
import columnTH from "./lang/th/column.json";
import maintenancePlanTH from "./lang/th/maintenance_plan.json";
import branchTH from "./lang/th/branch.json";
import vendorTH from "./lang/th/vendor.json";
import depreciationTH from "./lang/th/depreciation.json";
import subAssetTH from "./lang/th/sub_asset.json";
import modelAssetTH from "./lang/th/model.json";
import borrowAssetTH from "./lang/th/borrowreturn.json";
import maintenanceReportTH from "./lang/th/maintenance_report.json";
import borrowReturnStatusesTH from "./lang/th/borrowreturn_statuses.json";
import spongaTH from "./lang/th/sponga.json";

export default {
  home: HomeTH,
  asset: AssetTH,
  btn: btnTH,
  count: countTH,
  checkitem: checkitemTH,
  dashboard: dashboardTH,
  detailasset: detailasTH,
  dialog: dialogTH,
  docregas: docregasTH,
  filter: filterTH,
  history: historyTH,
  profile: profileTH,
  password: passwordTH,
  scan: scanTH,
  login: loginTH,
  selectcompany: selectcomTH,
  register: registerTH,
  registeritem: registeritemTH,
  approve: approveTH,
  adddate: adddateTH,
  addmin: adminTH,
  imgoneasset: imgoneassetTH,
  import: importTH,
  // maintenance: maintenanceTH,
  category: categoryTH,
  department: departmentTH,
  zone: zoneTH,
  column: columnTH,
  maintenance_plan: maintenancePlanTH,
  branch: branchTH,
  vendor: vendorTH,
  depreciation: depreciationTH,
  subasset: subAssetTH,
  model: modelAssetTH,
  borrow: borrowAssetTH,
  maintenance_report: maintenanceReportTH,
  borrow_return_statuses: borrowReturnStatusesTH,
  sponga: spongaTH,
};
