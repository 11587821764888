<template>
  <div v-if="customid === 2" class="card-form" @click="gotoMaintenancePlan">
    <img src="../../assets/icon-home/maintenance_plan.svg" />
    <div class="group-text">
      <h1>{{ $t("maintenance_plan.maintenance_plan") }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customid"],
  methods: {
    gotoMaintenancePlan() {
      this.$router.push("/MaintenancePlan");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-form {
  cursor: pointer;
  display: grid;
  width: 80%;
  grid-template-columns: auto 1fr;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 50px;
    height: 50px;
  }
  h1 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 400;
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }
}

.group-text {
  align-self: center;
}
</style>
