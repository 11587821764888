<template>
  <div>
    <div v-if="customid === 2">
      <div
        @click="gotoManageBranch()"
        class="responsive-bar-admin-menu"
        :style="$route.name == 'ManageBranch' ? 'color: #007afe' : ''"
      >
        <span> {{ $t("branch.name") }}</span>
      </div>
      <div
        @click="gotoManageVendor()"
        class="responsive-bar-admin-menu"
        :style="$route.name == 'ManageVendor' ? 'color: #007afe' : ''"
      >
        <span> {{ $t("vendor.name") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    customid: Number,
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
  },
  mounted() {},
  methods: {
    gotoManageBranch() {
      this.$router.push("/admin/ManageBranch");
    },
    gotoManageVendor() {
      this.$router.push("/admin/ManageVendor");
    },
  },
};
</script>
<style lang="scss" scoped>
.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}
.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0% 0%
      no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  &-submenu {
    padding-left: 1rem;
    & div {
      padding-top: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}
</style>
