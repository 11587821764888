<template>
  <div class="login" v-if="!beforeRefresh">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      id="pending-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('pending-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center px-2 pb-2">
        {{ $t("selectcompany.company_pending") }}
      </p>
      <!-- <div class="d-flex justify-content-center">
        <button class="btn-regist">{{ $t("selectcompany.contact") }}</button>
      </div> -->
    </b-modal>
    <b-modal
      class="p-2"
      id="expired-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('expired-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center px-2 pb-2">
        {{
          $t("selectcompany.company_expired", {
            companyName: selectedCompany.name,
            expireDate: selectedCompany.exp_date,
          })
        }}
      </p>
      <div class="d-flex justify-content-center">
        <!-- <button class="btn-regist">{{ $t("selectcompany.contact") }}</button> -->
        <button class="btn-login" @click="goToUpgrade">
          {{ $t("selectcompany.upgrade") }}
        </button>
      </div>
    </b-modal>
    <BackgroundLogo></BackgroundLogo>
    <div class="card-div">
      <!-- <img class="banner" src="../assets/Logo2.png" alt="" /> -->
      <div class="card-body">
        <p class="header-company">{{ $t("selectcompany.ch_com") }}</p>
        <b-row class="text-center justify-content-center">
          <b-row
            class="d-flex justify-content-center w-100"
            v-if="company !== null && company.length > 0"
          >
            <b-col
              cols="4"
              class="company_card cursor-pointer"
              v-for="(eachCompany, index) in company"
              :key="index"
              @click="toHome(eachCompany)"
            >
              <p class="company_name">{{ eachCompany.company.name_company }}</p>
              <p class="company_position">
                {{
                  eachCompany.company.branch_name == "Head Office"
                    ? $t("selectcompany.headoff")
                    : `${$t("selectcompany.br")} ${
                        eachCompany.company.branch_name
                      }`
                }}
              </p>
              <p class="company_role">
                {{ $t("selectcompany.pos") }} :
                {{ eachCompany.permission.role.name }}
              </p>
              <p
                class="company_role"
                style="font-size: 12px"
                :style="{
                  color: statusColor(eachCompany.package.status),
                }"
                v-if="
                  eachCompany.package
                    ? eachCompany.package.package_name &&
                      eachCompany.package.package_name == `free trial`
                    : false
                "
              >
                {{ eachCompany.package.package_name }}
                <br />
                {{ $t("selectcompany.exp") }}
                {{ formatDate(eachCompany.package.exp_date) }}
              </p>
              <p
                class="company_role"
                style="font-size: 12px"
                :style="{
                  color: statusColor(eachCompany.package.status),
                }"
                v-else-if="
                  eachCompany.package ? eachCompany.package.package_name : false
                "
              >
                {{ eachCompany.package.package_name }}
                <!-- Hide available assets from  Ninja Van Thailand -->
                <span v-if="eachCompany.company.id_company !== 196">
                  ({{
                    eachCompany.package.asset
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                  Assets)
                </span>
                <br />
                {{ $t("selectcompany.exp") }}
                {{ formatDate(eachCompany.package.exp_date) }}
                <br />
                {{
                  eachCompany.package.status === "pending"
                    ? "รอยืนยันการชำระเงิน"
                    : ""
                }}
              </p>

              <p
                class="company_role"
                style="color: #007afe; font-size: 12px"
                v-else
              >
                {{ $t("selectcompany.wait_qr") }}
              </p>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="d-flex justify-content-center" style="height: 15vh">
              <div class="no-company">
                <b-icon-building scale="1.5" /> <br />
                <p>{{ $t("selectcompany.no_com") }}</p>
              </div></b-col
            ></b-row
          >
        </b-row>
      </div>
      <div class="card-body" v-if="invited_company.length > 0">
        <p class="header-company">{{ $t("selectcompany.invite") }}</p>
        <b-row v-if="invited_company !== null && invited_company.length > 0">
          <b-col class="d-flex justify-content-center">
            <div
              class="invited_company_card"
              v-for="(eachCompany, index) in invited_company"
              :key="index"
            >
              <p class="company_name">{{ eachCompany.company.name_company }}</p>
              <p class="company_position">
                {{ eachCompany.company.branch_name }}
              </p>
              <p class="company_role">
                {{ $t("selectcompany.status") }} :
                {{ eachCompany.permission.role.name }}
              </p>
              <b-row>
                <b-col cols="12" class="text-right">
                  <b-button
                    class="decline_button button m-1"
                    @click="declineRequest(eachCompany)"
                  >
                    {{ $t("btn.canc") }}
                  </b-button>
                  <b-button
                    class="button m-1"
                    @click="acceptRequest(eachCompany)"
                  >
                    {{ $t("btn.conf") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="my-3" style="padding: 0 !important; margin: 0 !important">
        <b-col cols="12" style="margin-bottom: 1rem">
          <b-button @click="signOut"> {{ $t("btn.logout") }} </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BackgroundLogo from "@/components/BackgroundLogo.vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import { mapGetters } from "vuex";
import { sortBy } from "lodash";
import Swal from "sweetalert2";
import { featureFlags } from "../native-app-feature-flag";
import VConsole from "vconsole";
import Cookie from "js-cookie";

export default {
  data() {
    return {
      beforeRefresh: true,
      isLoading: false,
      company: null,
      invited_company: [],
      selectedCompany: { name: "", exp_date: "" },
    };
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "th") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "th";
      }
    },
    async signOut() {
      this.isLoading = true;
      await this.$store.dispatch("logout");
      this.isLoading = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    statusColor(status) {
      if (status === "exp") {
        return "red";
      }
      if (status === "pending") {
        return "orange";
      }
      return "#007afe";
    },
    async toHome(company) {
      if (company.is_confirm === "Confirm") {
        if (company.package.status === "exp" && !company.package.can_access) {
          this.selectedCompany.name = company.company.name_company;
          this.selectedCompany.exp_date = moment(
            company.package.exp_date
          ).format("DD/MM/YYYY");
          this.$bvModal.show("expired-modal");
          return;
        } else if (company.package.status === "pending") {
          this.$bvModal.show("pending-modal");
          return;
        } else if (company.package.can_access) {
          await this.$store.dispatch("saveSelectCompany", company);
          this.$router.push("/");
        }
      } else {
        this.$router.push("/approve");
      }
    },
    goToUpgrade() {
      window.open("https://www.verscan.com");
    },
    async getAllCompany() {
      let all_company = await this.$store.dispatch("selectCompany");
      all_company = sortBy(all_company, (company) => {
        return company.company.id_company;
      });

      this.company = all_company.filter((each) => {
        return each.invite_status == "Confirm";
      });
      this.invited_company = all_company.filter((each) => {
        return each.invite_status == "Invited";
      });
    },
    async acceptRequest(company) {
      // console.log(company.company.id_company)
      await this.$store.dispatch("acceptRequest", company.company.id_company);
      await this.getAllCompany();
    },
    async declineRequest(company) {
      await this.$store.dispatch("declineRequest", company.company.id_company);
      await this.getAllCompany();
    },
    goback() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    const vConsole = (() => {
      if (this.$store.getters.isTester) {
        return new VConsole();
      } else {
        return null;
      }
    })();
    this.isLoading = true;
    if (this.canUseCustom5) {
      this.$store.dispatch("clearPermission");
      this.$router.go();
    } else {
      this.$store.dispatch("clearPermission");
      this.beforeRefresh = false;
    }

    await this.getAllCompany();
    window.postMessage(document.cookie);
    if (featureFlags.cookieLogin) {
      window.sendMessage("cookie", document.cookie);
    }
    this.isLoading = false;
  },
  components: { BackgroundLogo, Loading },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      isFromProfile: "isFromProfile",
      canUseCustom5: "canUseCustom5",
    }),
  },
  // beforeCreate() {
  //   window.location.reload();
  // },

  // filters: {
  //   moment: function(date) {
  //     // if(date)
  //     // console.log(date)
  //     return moment(date).format("DD/MM/YYYY");
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.white-blue {
  color: #007afe;
  background: white;
  border: solid 1px #007afe;
}
.decline_button {
  background: lightgrey !important;
  color: white;
}
.decline_button:hover {
  color: #007afe;
}
.white-blue {
  color: #007afe;
  background: white;
  border: solid 1px #007afe;
}
.company_card {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background: #ffffff;
  border: 1px solid #007afe;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 300px !important;
}
.invited_company_card {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #007afe;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 300px !important;
}
.company_name {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: black;
}
.company_position {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.company_role {
  text-overflow: ellipsis;
  color: black;
  font-size: 14px;
}

.package {
  font-size: 12px;
  line-height: 14px;
  color: black;
}
.container {
  display: flex;
  justify-content: center;
}
.link-color {
  color: $color-link;
}
.header-text {
  color: $font-black;
}

@media only screen and (min-width: 768px) {
  .card-form {
    background-color: white;
    // height: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
}
// .card-body {
//   display: flex;
//   justify-content: center;
//   align-content: center;
// }
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #7c7c7c;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.header-company {
  font-weight: 500;
  font-size: 24px;
  color: black;
}
.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.input-group {
  background-color: #e0e0e0;
}
@media only screen and (min-width: 1024px) {
  .card-body {
    margin-left: 5%;
    margin-right: 5%;
  }
}
.button {
  font-size: 14px;
  width: 100px;
}
.no-company {
  margin: auto;
  color: grey;
  font-size: 18px;
}

.btn-login {
  margin: 0 10px;
  width: 160px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: $background-gradient-btn-blue;
}
.btn-regist {
  margin: 0 10px;
  width: 160px;
  height: auto;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
</style>
