<template>
  <div
    class="d-flex justify-content-between align-items-center border-top py-3"
    style="cursor: pointer"
    @click="$emit('toggle')"
  >
    <div>
      {{ label }}
    </div>
    <img
      v-if="expand"
      width="16"
      height="16"
      src="../image/shrink.png"
      alt=""
    />
    <img v-else width="16" height="16" src="../image/expand.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "ExpandHeightHeader",
  emit: ["toggle"],
  props: {
    expand: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
