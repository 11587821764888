<template>
  <b-container fluid class="bg">
    <b-row>
      <b-col cols="3" class="">
        <!-- <div class="back-icon" v-if="isFromProfile && isSelecCompany">
          <img src="../assets/previous.svg" width="30" @click="goback" />
        </div> -->
      </b-col>
      <b-col cols="6" class="d-flex justify-content-center">
        <img class="banner" src="../assets/Logo2.png" alt="" />
      </b-col>
      <b-col cols="3" class="d-flex"> </b-col>
    </b-row>

    <!-- <div class="bg d-flex">
      <div class="back-icon">
        <img src="../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <img class="banner" src="../assets/Logo2.png" alt="" />
    </div>
    <div class="body"></div> -->
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    console.log("route", this.$route);
    document.body.className.replace(/\bhome\b/g, "");
  },
  methods: {
    goback() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters({
      isFromProfile: "isFromProfile",
    }),
    isSelecCompany() {
      return this.$route.name === "SelectCompany";
    },
  },
};
</script>

<style scoped>
.banner {
  width: 136px;
  height: 100%;
  top: 0px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  z-index: 10;
}

.bg {
  /* position: fixed; */
  z-index: -10;
  /* margin: auto; */
  width: 100%;
  height: 270px;
  /* border-radius: 1rem; */
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.body {
  position: fixed;
  z-index: -9;
  margin: auto;
  width: 100%;
  height: 50%;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}
@media only screen and (max-width: 412px) {
  body,
  html,
  * {
    font-size: 14px;
  }

  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
</style>
