<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <b-dropdown
        class=""
        id="edit-menu"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        style="outline: none;"
      >
        <template #button-content>
          <div class="status-box d-flex">
            <p class="current-status" :class="dataFromApi" v-if="!change">
              {{ $t(`maintenance_report.${dataFromApi}`) }}
            </p> 
             <p class="current-status" :class="statusColor" v-else>
              {{ maintenanceStatus.text }}
            </p>
            <img
              src="@/assets/Maintenancing/maintenance-drop-arrow.svg"
              alt="dropdown"
              width="100%"
            />
          </div>
        </template>
        <b-dropdown-item
          v-for="option in status.options"
          :key="option.value"
          @click="changeStatus(option)"
        >
          <p>{{ option.text }}</p>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { emit } from "process";
import ClickOutside from "vue-click-outside";

export default {
  props: {
    dataFromApi: {
      type: String,
    },
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      change: false,
      // maintenanceStatus: this.$props.dataFromApi,
      maintenanceStatus: '',
      newStatus: "",
      status: {
        name: "",
        options: [
          {
            text: this.$t("maintenance_report.pending"),
            value: "pending",
          },
          {
            text: this.$t("maintenance_report.in_progress"),
            value: "in_progress",
          },
          {
            text: this.$t("maintenance_report.complete"),
            value: "complete",
          },
          // {
          //   text: this.$t("maintenance_report.other"),
          //   value: "other",
          // },
        ],
      },
      selectedOption: "",
    };
  },
  methods: {
    changeStatus(value) {
      this.change = true;
      this.$emit("upper-status-detail", value.value);
      // console.log(value.value)
      return (this.maintenanceStatus = value);
    },
  },

  computed: {
    statusColor() {
      let statusClass = "";
      if (this.maintenanceStatus.value === "pending") {
        statusClass = "pending";
      } else if (this.maintenanceStatus.value === "in_progress") {
        statusClass = "in_progress";
      } else if (this.maintenanceStatus.value === "complete") {
        statusClass = "complete";
      } else if (this.maintenanceStatus.value === "cancel") {
        statusClass = "cancel";
      } else if (this.maintenanceStatus.value === "reject") {
        statusClass = "reject";
      } else if (this.maintenanceStatus.value === "other") {
        statusClass = "other";
      }
      return statusClass;
    },
  },
    created() {
      if (this.$props.dataFromApi === "pending") {
        this.status.options = this.status.options.filter(
          (option) => option.value !== "pending"
        );
      } else if (this.$props.dataFromApi === "in_progress") {
        this.status.options = this.status.options.filter(
          (option) => option.value !== "pending" && option.value !== "in_progress"
        );
      }
    },
};
</script>

<style lang="scss" scoped>
.current-status {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding: 4px 10px;
  margin: auto;
}
.status-box {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
img {
  padding: 0 5px;
}

.current-status.pending {
  color: #fea800;
}

.current-status.in_progress {
  color: #4685ff;
}
.current-status.complete {
  color: #00ab1b;
}
.current-status.cancel {
  color: #616161;
}
.current-status.reject {
  color: #f46363;
}
.current-status.other {
  color: #a864d2;
}
</style>
