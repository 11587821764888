<template>
  <ag-grid-vue
    style="width: 100%; height: 450px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    @grid-ready="onGridReady"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../Table/components/iconEditCheck.vue";
import btnConfirmlost from "../Table/components/btnConfirmlost.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: {
    AgGridVue,
    iconEditCheck,
    btnConfirmlost,
  },
  data() {
    return {
      isSelectedAsset: false,
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
    };
  },
  props: {
    dataTable: [],
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("column.order"),
        field: "index",
        maxWidth: 110,
        suppressSizeToFit: true,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("column.column_name"),
        field: "name",
        sortable: true,
      },
      {
        headerName: this.$t("column.manage"),
        field: "edit",
        cellRendererFramework: "iconEditCheck",
        sortable: true,
        suppressSizeToFit: true,
      }
    ];
  },
  computed: {
    rowData() {
      return this.dataTable.map((column, index) => {
          return {
              index,
              name:column.name,
              edit: {id_user: column.id_other_column},
          }
      })
    },
  },
  mounted() {
    console.log(this.dataTable);
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    onSelectionChanged() {
      this.isSelectedAsset =
        this.gridApi.getSelectedNodes().length !== 0 ? true : false;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.dataTable.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    }
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
