<template>
  <div v-if="params.value.status === 'Confirm'">
    <img src="@/assets/check_confirm.svg" />
  </div>
  <div v-else-if="params.value.status === 'Invited'">
    <button
      data-toggle="modal"
      data-target="#confirmLostModal"
      class="btn-confirm"
      @click="showConfirm(params.value.user)"
    >
      {{ $t("btn.conf_us") }}
    </button>
  </div>
  <div v-else-if="params.value.status === 'Decline'">
    <button class="btn-decline">{{ $t("btn.conf_us") }}</button>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log("xxx", this.params.value.status);
  },
  methods: {
    showConfirm(user) {
      this.$parent.$parent.$parent.showConfirm(user);
    }
  }
};
</script>

<style scoped>
.btn-confirm {
  color: #fff;
  background: linear-gradient(180deg, #007afe 0%, #164181 100%);
  border-radius: 5px;
  border: none;
  padding: 0 1em;
}
.btn-decline {
  color: #fff;
  background: #cccccc;
  border-radius: 5px;
  border: none;
  padding: 0 1em;
}
</style>
