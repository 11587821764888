<template>
  <div v-if="customid === 2" class="text-start">
    <label for="">{{ $t("asset.region_province") }}</label>
    <p class="sub-label">
      {{ isNull(assetInfo.sector_name) }}
    </p>
    <label for="">{{ $t("asset.branch") }}</label>
    <p class="sub-label">
      {{ isNull(assetInfo.branch_name) }}
    </p>
    <label for="">{{ $t("asset.insurance_start") }}</label>
    <p class="sub-label">
      {{ assetInfo.custom_start_time | formatDate }}
    </p>
    <label for="">{{ $t("asset.insurance_end") }}</label>
    <p class="sub-label">
      {{ assetInfo.custom_end_time | formatDate }}
    </p>
    <label for="">{{ $t("asset.vendor") }}</label>
    <p class="sub-label">
      {{ isNull(assetInfo.vendor_name) }}
    </p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["customid", "assetInfo"],
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "-";
    },
  },
  methods: {
    isNull(data) {
      return data ? data : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-label {
  font-size: 18px;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;
}

label {
  color: #4b4b4b;
}
</style>
