<template>
  <div class="error-dialog">
    <img class="icon" src="../assets/cancle.svg" />
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: ["msg"]
};
</script>

<style scoped>
.icon {
  width: 36px;
  height: 36px;
  padding-right: 0.5rem;
}
.error-dialog {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 68px;
  /* padding: 1.5rem; */
  /* border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem; */
  border-radius: 0px 0px 40px 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0%);
  left: 50%;
  background: white;
  z-index: 10000;
}

@media only screen and (min-width: 1025px) {
  .error-dialog {
    position: absolute;
    width: 500px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
  }
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .error-dialog {
    position: absolute;
    width: 280px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
  }
}

.error-dialog p {
  margin-bottom: 0;
  margin-top: -0.2rem;
  font-size: 18px;
  color: black;
}
.header-text {
  padding: 0px;
  margin: 0;
  font-size: 18px;
}
</style>
