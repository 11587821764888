<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <b-dropdown
        class=""
        id="edit-menu"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        style="outline: none;"
      >
        <template #button-content>
          <div class="urgency-box d-flex">
            <p class="current-urgency" :class="dataFromApi" v-if="!change">
              {{ $t(`maintenance_report.${dataFromApi}`) }}
            </p>
            <p class="current-urgency" :class="urgencyColor" v-else>
              {{ maintenanceUrgency.text }}
            </p>
            <img
              src="@/assets/Maintenancing/maintenance-drop-arrow.svg"
              alt="dropdown"
              width="100%"
            />
          </div>
        </template>
        <b-dropdown-item
          v-for="option in options"
          :key="option.value"
          @click="changeUrgency(option)"
        >
          <p>{{ option.text }}</p>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  props: {
    dataFromApi: {
      type: String,
    },
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      change: false,
      maintenanceUrgency: "",
      options: [
        {
          text: this.$t("maintenance_report.null"),
          value: "null",
        },
        {
          text: this.$t("maintenance_report.high"),
          value: "high",
        },
        {
          text: this.$t("maintenance_report.medium"),
          value: "medium",
        },
        {
          text: this.$t("maintenance_report.low"),
          value: "low",
        },
      ],
      selectedOption: "",
    };
  },
  methods: {
    changeUrgency(value) {
      this.change = true;
      this.$emit("upper-urgency-detail", value.value);
      return (this.maintenanceUrgency = value);
    },
  },

  computed: {
    urgencyColor() {
      let urgencyClass = "";
      if (this.maintenanceUrgency.value === "high") {
        urgencyClass = "high";
      } else if (this.maintenanceUrgency.value === "low") {
        urgencyClass = "low";
      } else if (this.maintenanceUrgency.value === "medium") {
        urgencyClass = "medium";
      } else {
        urgencyClass = "null";
      }
      return urgencyClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.current-urgency {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding: 4px 10px;
  margin: auto;
  &.medium {
    color: #fea800;
  }
  &.null {
    color: #000000;
  }
  &.low {
    color: #00ab1b;
  }
  &.high {
    color: #f46363;
  }
}
.urgency-box {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
img {
  padding: 0 5px;
}
</style>
