<template>
  <p class="text-grey">
    {{
      $t("asset.total_page_count", {
        first: firstItem,
        last: lastItem,
        total: total,
      })
    }}
  </p>
</template>

<script>
export default {
  props: ["page", "perPage", "currentAmount", "total"],
  computed: {
    firstItem() {
      return 1 + (this.page - 1) * this.perPage;
    },
    lastItem() {
      let last = this.currentAmount + this.firstItem - 1;
      if (last > this.total) {
        last = this.total;
      }
      return last;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-grey {
  font-size: 14px;
  color: #7c7c7c;
}
</style>
