<template>
  <div class="scan-qr">
    <div class="card-div">
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="400px"
              ref="signaturePad"
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
            />

            <!-- <label>ระบุตำแหน่งสินทรัพย์</label> -->

            <div class="row row-btn-sign mt-2">
              <div>
                <div class="icon-back-undo">
                  <img
                    src="../assets/undo.svg"
                    width="30px"
                    height="30px"
                    class="icon-back"
                    @click="undo"
                  />
                </div>
              </div>
              <div>
                <button class="btn btn-outline-secondary" @click="clear">
                  {{ $t("btn.del_all") }}
                </button>
                <button
                  class="btn btn-outline-primary"
                  data-dismiss="modal"
                  @click="save"
                >
                  {{ $t("btn.save_sig") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-form card-form-login mt-5">
        <img class="banner" src="../assets/contract.png" alt="" />

        <p class="detail">
          {{ $t("count.sign_cf") }}<br />
          {{ $t("count.sign_cf2") }}
        </p>
        <div
          class="camera-frame"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <img
            v-if="url"
            :src="url"
            width="100%"
            height="100%"
            style="object-fit:scale-down;"
          />

          <!-- <VueSignaturePad id="signature" width="100%" height="100%" /> -->
        </div>

        <div class="profile-detail mt-4">
          <p>{{ getUserDetail.name }}</p>
          <small>{{ getUserDetail.role }}</small>
        </div>

        <div class="button-group">
          <button
            class="btn btn-sign"
            data-toggle="modal"
            data-target="#exampleModal"
            @click="open()"
          >
            {{ $t("btn.sig_cer") }}
          </button>

          <button class="btn btn-sign btn-auto" for="fusk" type="submit">
            <label class="custom-input-file" for="fusk">
              {{ $t("btn.up_sigimg") }}
            </label>
          </button>

          <input
            id="fusk"
            type="file"
            accept="image/png, image/jpeg"
            name="photo"
            style="display: none; margin:0px !important"
            @change="onFileChange($event)"
          />
        </div>
      </div>
      <button class="btn btn-back" @click="gotoDashboard()">
        {{ $t("btn.back") }}
      </button>

      <div class="line-break"></div>
    </div>
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
// import vueSignarture from "vue-signature";
// import Sign from "@/components/Signature.vue";
import Axios from "axios";
import Compressor from "compressorjs";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      pictureFile: null,
      url: "",
      signShow: false,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      // dataUrl: "https://avatars2.githubusercontent.com/u/17644818?s=460&v=4"
    };
  },
  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
    }),
  },
  methods: {
    onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      this.pictureFile = file;

      this.url = URL.createObjectURL(file);
      this.upload();
    },
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      console.log("=== End ===");
    },
    gotoDashboard() {
      this.$router.back();
    },
    open() {
      console.log(`open`);

      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      });
    },

    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    blobToFile(theBlob, fileName) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    addWaterMark() {
      var _this = this;
      _this.$refs.signature.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    // fromDataURL(url) {
    //   console.log("formDataURL: ", url);
    //   var _this = this;
    //   _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    // },
    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log("save", data);

      let _blob = this.dataURItoBlob(data);
      console.log("blob ", _blob);

      let _file = this.blobToFile(_blob, "sign1.png");
      this.pictureFile = _file;
      console.log("file ", _file);
      console.log("pictureFile ", this.pictureFile.name);

      this.url = URL.createObjectURL(_file);
      console.log("url-> ", this.url);

      this.upload();
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      const id_company = 1;
      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      // console.log(`fileforupload`, fileforupload);
      console.log("fddd", file);
      console.log("get user detail -> ", this.getUserDetail);
      Axios.post("https://api.verscan.com/file/api/file/create", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        console.log("res.data.data;", res.data.data);
        this.url_new = res.data.data.uploadFileAtLevel.url;
        console.log("url_new ", this.url_new);
      });
    },

    change() {
      this.options = {
        penColor: "#00f",
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-detail {
  small {
    margin-top: -2rem;
  }
}
.icon-back-undo {
  padding-left: 0.5rem;
  display: flex;
  margin-left: auto;
}
.row-btn-sign {
  justify-content: space-between;
  padding: 0.4rem;
  button {
    margin: 0.1rem;
    font-size: 12px !important;
  }
}
#signature {
  border: 3px solid $font-grey;
  border-radius: 25px;
}
.btn-back {
  width: 106px;
  height: 41px;
  background: #7c7c7c 0% 0% no-repeat padding-box;
}
.btn-indoor-modal {
  text-align: center;
  margin: auto;
  margin-top: 1rem;
}

.indoor-map-modal {
  height: 40vh;
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;
}

.modal-content label {
  color: #4b4b4b;
  padding: 0.4rem;
}

.button-group {
  margin: auto;
  text-align: center;
  display: grid;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.btn-sign {
  margin-top: 1rem;
  width: 162px;
}

.detail {
  padding-top: 1rem;
  font-size: 11px;
  color: #4b4b4b;
}

.header-text {
  font-size: 30px;
  font-weight: 400;
  padding: 2rem;
  color: white;
}

.card-form {
  margin: auto;
  width: 80%;
  padding: 2rem;
  margin-bottom: 1rem;
}
.banner {
  width: 40px;
  z-index: 10;
}

.camera-frame {
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bcbaba;
  border-radius: 22px;
  padding: 0.4rem;
}
</style>
