<template>
  <div class="responsive-bar">
    <AdminMenuBar />
    <div class="full-page">
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ menuText }}</div>
      </h1>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminMenuBar from "../Maintenance/MaintenanceMenubar.vue";

export default {
  components: {
    AdminMenuBar,
    // AdminSlideMenu,
  },
  data() {
    return {};
  },
  methods: {
    gotoHome() {
      switch (this.$route.name) {
        default:
          this.$router.push({ name: "Home" });
          return;
      }
    },
  },
  computed: {
    menuText() {
      switch (this.$route.name) {
        case "MyMaintenanceReportList":
          return this.$t("maintenance_report.my-maintenance-report");
        case "MaintenanceDashboard":
          return this.$t("maintenance_report.dashboard");
        case "MaintenanceTechnician":
          return this.$t("maintenance_report.technician-data");
        case "MyMaintenanceList":
          return this.$t("maintenance_report.maintenance-list");
        case "MaintenanceReportAll":
          return this.$t("maintenance_report.maintenance-all");
        case "MaintenanceHistory":
          return this.$t("maintenance_report.history");
        default:
          return "ยังไม่มีชื่อ";
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @media only screen and (min-width: 1025px) {
//   .responsive-bar {
//     display: grid;
//     grid-template-columns: 250px calc(100vw - 250px);
//     grid-template-rows: minmax(100vh, auto);
//   }
// }
.full-page {
  width: 100%;
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 1025px) {
    display: none;
  }
}
</style>
