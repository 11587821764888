<template>
  <div v-if="customid === 2" class="text-start">
    <p class="info-label">{{ $t("asset.region_province") }}</p>
    <p class="info-text">
      {{ isNull(assetInfo.sector && assetInfo.sector.name) }}
    </p>
    <p class="info-label">{{ $t("asset.branch") }}</p>
    <p class="info-text">
      {{ isNull(assetInfo.branch && assetInfo.branch.name) }}
    </p>
    <p class="info-label">{{ $t("asset.insurance_start") }}</p>
    <p class="info-text">
      {{ assetInfo.custom_start_time | formatDate }}
    </p>
    <p class="info-label">{{ $t("asset.insurance_end") }}</p>
    <p class="info-text">
      {{ assetInfo.custom_end_time | formatDate }}
    </p>
    <p class="info-label">{{ $t("asset.vendor") }}</p>
    <p class="info-text">
      {{ isNull(assetInfo.vendor && assetInfo.vendor.name) }}
    </p>
    <p class="info-text">
      {{ $t("vendor.tel") }}:
      {{ isNull(assetInfo.vendor && assetInfo.vendor.tel) }}
    </p>
    <p class="info-text">
      {{ $t("vendor.email") }}:
      {{ isNull(assetInfo.vendor && assetInfo.vendor.email) }}
    </p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["customid", "assetInfo"],
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "-";
    },
  },
  methods: {
    isNull(data) {
      return data ? data : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-label {
  font-size: 18px;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;
}

label {
  color: #4b4b4b;
}

.info-label {
  font-size: 18px;
  color: #000;
}

.info-text {
  font-size: 16px;
  color: #7c7c7c;
}
</style>
