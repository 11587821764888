<template>
  <div v-if="customid === 2" class="text-start mt-3">
    <p class="info-label">
      {{ $t("maintenance_plan.maintenance_history") }}
    </p>
    <p class="info-label">{{ $t("maintenance_plan.maintenance_amount") }}</p>
    <p class="info-text">
      {{ repairAmount ? repairAmount.toLocaleString() : "0" }}
      {{ $t("maintenance_plan.round") }}
    </p>
    <p class="info-label">{{ $t("maintenance_plan.total_maintenance_price") }}</p>
    <p class="info-text">
      {{ numberComma(repairCost) }}
    </p>
    <MaintenanceTable :dataTable="repairData" />
    <button
      class="manage-btn d-block mx-auto my-3"
      @click="gotoManageMaintenance"
    >
      <img src="@/assets/wrench.svg" alt="" />
      {{ $t("maintenance_plan.manage_maintenance") }}
    </button>
  </div>
</template>

<script>
import MaintenanceTable from "../CustomTable/AssetMaintenanceTable.vue";

import axios from "axios";
import { baseUrl } from "../../util/backend.js";
import { authHeader } from "../../store/actions";
export default {
  props: ["customid"],
  components: {
    MaintenanceTable,
  },
  data() {
    return {
      repairData: [],
      repairCost: 0,
      repairAmount: 0,
    };
  },
  methods: {
    numberComma(num) {
      let number = num;
      if (number) {
        number = number.toFixed(2);
      }
      return number
        ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        : "0";
    },
    gotoManageMaintenance() {
      this.$router.push({
        name: "MaintenanceHistoryDetail",
        params: {
          assetId: this.$route.params.id,
        },
      });
    },
    async loadData() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}maPlan/getAllRepairReportById_asset`,
          {
            params: {
              id_company: id_company,
              id_asset: this.$route.params.id,
              show_cancel: false,
              page: 1,
              limit: 5,
            },
            ...authHeader(),
          }
        );
        this.idFixedAsset = res.data.id_fixed_asset;
        this.repairCost = res.data.cost;
        this.repairAmount = res.data.count;
        this.repairData = res.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.sub-label {
  font-size: 18px;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;
}

label {
  color: #4b4b4b;
  padding: 0.4rem;
}

.manage-btn {
  background-color: #fff;
  border: none;
  color: #015fc3;
  padding: 5px 25px;
  border-radius: 5px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.info-label {
  font-size: 18px;
  color: #000;
}

.info-text {
  font-size: 16px;
  color: #7c7c7c;
}
</style>
