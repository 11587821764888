import axios from "axios";
import Cookies from "js-cookie";
import { authHeader } from "../actions";
import { borrowUrl } from "@/util/backend";

export const actions = {
  setBorrowDate({ commit }, payload) {
    commit("setBorrowDate", payload);
  },
  setSelectedAssetBorrow({ commit }, payload) {
    commit("setSelectedAssetBorrow", payload);
  },
  removeSelectedAssetBorrow({ commit }, payload) {
    commit("removeSelectedAssetBorrow", payload);
  },
  clearBorrowState({ commit }) {
    commit("clearBorrowState");
  },
  setBorrowOrderForm({ commit }, payload) {
    commit("setBorrowOrderForm", payload);
  },
  clearBorrowOrderForm({ commit }) {
    commit("clearBorrowOrderForm");
  },
  async fetchBorrowSettings({ commit }) {
    const { company } = JSON.parse(Cookies.get("selectedcompany"));
    try {
      const res = await axios(
        `${borrowUrl()}setting/company/id/${company.id_company}`,
        authHeader()
      );
      const data = res.data;
      commit("setBorrowSettings", {
        is_borrow_return_scan: data.is_borrow_return_scan,
        is_borrow_return_gap: data.is_borrow_return_gap,
      });
    } catch (error) {
      console.error(error);
    }
  },
};
