<template>
  <div class="register">
    <!-- <Background></Background> -->
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      loader="dots"
    ></Loading>
    <div class="card-div">
      <h1 class="header-text">
        <div class="back-icon" @click="gotoLogin()">
          <img src="../assets/previous.svg" width="30" />
        </div>
        <div class="topic">{{ $t("register.us_reg") }}</div>
      </h1>
      <!-- Frame profile -->
      <div class="frame-profile" :class="{ 'is-invalid': invalid.pic }">
        <img v-if="url" :src="url" class="banner-profile" />
        <img v-else class="banner" src="../assets/person-fill.svg" alt="" />

        <div class="upload-icon-plus">
          <label for="fusk">
            <img src="../assets/add.svg" style="cursor: pointer" />
          </label>
          <input
            id="fusk"
            type="file"
            accept="image/png, image/jpeg"
            name="photo"
            style="display: none; margin: 0px !important"
            @change="onFileChange($event)"
          />
        </div>
      </div>
      <div class="card-form-login">
        <!-- Form -->
        <form @submit.prevent="register" id="form">
          <div class="input-group">
            <label
              >{{ $t("register.name") }}<span class="require-red">*</span>
            </label>

            <input
              type="text"
              :class="{ 'is-invalid': invalid.name }"
              v-model="userRegis.name"
            />
          </div>

          <div class="input-group">
            <label
              >{{ $t("register.email") }}<span class="require-red">*</span>
            </label>
            <input type="email" v-model="userRegis.email" />
          </div>

          <div class="input-group">
            <label
              >{{ $t("register.pw") }}<span class="require-red">*</span></label
            >
            <input
              type="password"
              v-model="userRegis.password"
              minlength="8"
              :class="{ 'is-invalid': invalid.password }"
            />
            <span class="sub-text" :class="{ 'text-red': invalid.password }">{{
              $t("register.pass8")
            }}</span>
          </div>

          <div class="input-group">
            <label
              >{{ $t("register.conf_pw") }}<span class="require-red">*</span>
            </label>
            <input
              type="password"
              v-model="userRegis.confirm_password"
              :class="{ 'is-invalid': invalid.confirm_password }"
            />
          </div>

          <div class="input-group">
            <label
              >{{ $t("register.phone") }}<span class="require-red">*</span>
            </label>
            <input
              type="tel"
              v-model="userRegis.phone"
              maxlength="10"
              :class="{ 'is-invalid': invalid.phone }"
            />
          </div>

          <!-- <div class="input-group">
            <label>{{ $t("register.permission") }} </label>
            <input
              type="text"
              v-model="userRegis.position"
              disabled
              :class="{ 'is-invalid': invalid.position }"
            />
          </div> -->

          <!-- <div class="input-group">
            <label
              >{{ $t("register.pos") }}<span class="require-red">*</span>
            </label>
            <input
              type="text"
              v-model="userRegis.position_company"
              :class="{ 'is-invalid': invalid.position_company }"
            />
          </div> -->

          <!-- <div class="input-group" style="padding-bottom: 2rem">
            <label
              >{{ $t("register.dep") }}<span class="require-red">*</span>
            </label>

            <input
              type="text"
              v-model="userRegis.department"
              :class="{ 'is-invalid': invalid.department }"
            />
          </div> -->
          <div class="d-flex align-items-center input-group">
            <input
              class="me-3"
              type="checkbox"
              id="accept"
              name="accept"
              v-model="acceptTerms"
            />

            <p
              v-if="$i18n.locale === 'th'"
              class="m-0"
              style="background-color: #fff;"
            >
              คุณยอมรับ
              <u style="color: #015fc3; cursor: pointer" @click="goToPolicy"
                >นโยบายความเป็นส่วนตัว</u
              >
              และ
              <u
                style="color: #015fc3; cursor: pointer"
                @click="goToDataDeletion"
                >การลบข้อมูล</u
              >
              ของ VERSCAN
            </p>
            <p v-else class="m-0">
              I agree to VERSCAN's
              <u style="color: #015fc3; cursor: pointer" @click="goToPolicy"
                >Privacy Policy</u
              >, and
              <u
                style="color: #015fc3; cursor: pointer"
                @click="goToDataDeletion"
                >Data Deletion.</u
              >
            </p>
          </div>
          <button class="btn btn-regis" type="submit" :disabled="!acceptTerms">
            {{ $t("register.register") }}
          </button>
        </form>
      </div>
      <div class="line-break"></div>
    </div>
  </div>
</template>

<script>
// import Background from "@/components/Background.vue";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";
import axios from "axios";
import Swal from "sweetalert2";
import baseUrl from "../util/backend";

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      showSucces: true,
      onCancel: false,
      url: null,
      pictureFile: null,
      customName: null,
      userRegis: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        position: "",
        department: "",
        id_company: "",
        id_permission: "",
        position_company: "",
      },
      invalid: {
        name: false,
        email: false,
        password: false,
        confirm_password: false,
        phone: false,
        position: false,
        department: false,
        id_company: false,
        id_permission: false,
        pic: false,
        passworld_text: false,
        position_company: false,
      },
      selecteddepartment: "",
      selectedrole: "",
      optiondepartment: ["บัญชี", "ซ่อมบำรุง", "ไอที", "ทั่วไป"],
      selectroleacc: ["บัญชี", "หัวหน้าบัญชี"],
      selectroletechs: ["ช่าง"],
      selectroleit: ["ไอทีซับพอร์ท", "โปรแกรมเมอร์"],
      selectroleother: ["พนักงานขาย", "พนักงานการตลาด"],
      userSendData: "",
      url_new: null,
      acceptTerms: false,
    };
  },
  async mounted() {},
  computed: {
    selecterole() {
      let selecteddepartment = this.selecteddepartment;
      if (selecteddepartment === this.$t("admin.acc")) {
        return this.selectroleacc;
      }
      if (selecteddepartment === this.$t("admin.maintain")) {
        return this.selectroletechs;
      }
      if (selecteddepartment === this.$t("admin.it")) {
        return this.selectroleit;
      }
      if (selecteddepartment === this.$t("admin.general")) {
        return this.selectroleother;
      }
      return null;
    },
  },
  methods: {
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    async register() {
      if (this.acceptTerms) {
        const strongPassword = new RegExp("(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})");

        if (this.userRegis.name == "" || this.userRegis.name == null) {
          this.invalid.name = true;
          // alert("here");
        } else {
          this.invalid.name = false;
        }

        if (this.url_new == null || this.url_new == "") {
          this.invalid.pic = true;
          alert(this.$t("dialog.upload_img"));
        } else {
          this.invalid.pic = false;
        }
        if (this.userRegis.password !== this.userRegis.confirm_password) {
          this.invalid.password = true;
          this.invalid.confirm_password = true;
          alert(this.$t("dialog.incorrect_pw"));
        } else {
          this.invalid.password = false;
          this.invalid.confirm_password = false;
        }
        if (!strongPassword.test(this.userRegis.password)) {
          this.invalid.password = true;
          this.invalid.confirm_password = true;
          this.invalid.passworld_text = true;
          // alert(`รหัสผ่านต้องมีอักขระ 8 ตัว มีทั้งตัวอักษรและตัวเลขผสมกัน`);
        } else {
          this.invalid.password = false;
          this.invalid.confirm_password = false;
          this.invalid.passworld_text = false;
        }
        // if (
        //   this.userRegis.department == null ||
        //   this.userRegis.department == ""
        // ) {
        //   this.invalid.department = true;
        // } else {
        //   this.invalid.department = false;
        // }
        // if (this.userRegis.position == null || this.userRegis.position == "") {
        //   this.invalid.position = true;

        //   // alert("กรุณาเลือกตำแหน่ง");
        // } else {
        //   this.invalid.position = false;
        // }
        if (!this.userRegis.phone) {
          this.invalid.phone = true;
        } else {
          this.invalid.phone = false;
        }
        // if (!this.userRegis.position_company) {
        //   this.invalid.position_company = true;
        // } else {
        //   this.invalid.position_company = false;
        // }
        if (
          !this.invalid.name &&
          !this.invalid.email &&
          !this.invalid.password &&
          !this.invalid.confirm_password &&
          !this.invalid.phone &&
          !this.invalid.pic
        ) {
          // console.log(`pictureFile`, this.pictureFile);

          this.isLoading = true;
          const data = {
            email: this.userRegis.email,
            password: this.userRegis.password,
            image_url: this.url_new,
            name: this.userRegis.name,
            phone: this.userRegis.phone,
            role: null,
            department: null,
            id_company: null,
            id_permission: null,
            position_company: null,
            customName: null,
          };
          console.log("data:", data);
          const response = await axios.post(`${baseUrl()}user/register`, {
            data,
          });
          if (response.data.message) {
            this.isLoading = false;
            alert(response.data.message);
          } else {
            this.gotoLogin();
          }
        }
      }
    },
    async onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }

      this.url = URL.createObjectURL(file);
      this.upload();
    },
    async upload() {
      this.$store.dispatch("setCustomName", this.customName);
      const fileforupload = await this.compressImage(this.pictureFile);

      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      console.log("res.data.data;", res.data.data);
      this.url_new = res.data.data.uploadFileAtLevel.url;
    },
    goToPolicy() {
      window.open("https://verscan.com/policy", "_blank");
    },
    goToDataDeletion() {
      window.open("https://verscan.com/datadeletion", "_blank");
    },
    gotoLogin() {
      this.$store.commit("setRegisStatus", false);
      this.$router.push("/login");
    },
    gotoProfile() {
      this.$router.push("/profile");
    },
    gotoApprove() {
      if (this.$route.path != "/approve") {
        this.$router.push("/approve").catch((error) => {
          if (
            error.name !== "NavigationDuplicated" &&
            !error.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(error);
          }
        });
      }
    },
  },
  watch: {
    userRegis: {
      handler(val) {
        if (this.userRegis.password) {
          this.userRegis.password = this.userRegis.password.trimStart();
          this.userRegis.password = this.userRegis.password.trimEnd();
        }

        if (this.userRegis.confirm_password) {
          this.userRegis.confirm_password = this.userRegis.confirm_password.trimStart();
          this.userRegis.confirm_password = this.userRegis.confirm_password.trimEnd();
        }

        console.log(this.userRegis.confirm_password);
        console.log(this.userRegis.password);
      },
      deep: true,
    },
  },
  components: { Loading },
};
</script>

<style lang="scss" scoped>
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

input {
  height: 2.1rem;
}
.back-icon {
  position: absolute;
  padding-left: 1rem;
  cursor: pointer;
}
.sub-text {
  margin-top: 2px;
  text-align: left;
  font: normal normal normal 11px/18px Mitr;
  letter-spacing: 0px;
  color: #7c7c7c;
  opacity: 1;
  margin-right: auto;
  background-color: white !important;
}

.frame-profile {
  position: absolute;
  // top: -0.2rem;
  top: 173px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  /* text-align: center; */
  margin: auto;
  background: white;
  border-radius: 50%;
  border: 5px solid white;
  z-index: 90;
  // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
}

.upload-icon-plus {
  z-index: 90;
  position: absolute;
  bottom: 30%;
  right: 1px;

  img {
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 30%;
    width: 31px;
    height: 31px;
  }
}
.upload-icon-content {
  display: flex;
  font-size: 4rem;
  align-items: center;
}
.banner {
  margin-top: -0.5rem;
  height: 4rem;
  width: 4rem;
  z-index: 10;
}

.banner-profile {
  height: 90%;
  width: 90%;
  border-radius: 50%;
  object-fit: cover;
}
.register {
  z-index: 100;
}

.btn-regis {
  padding: 0.55rem 1.75rem 0.55rem 1.75rem;
}

.link-forget {
  display: block;
  padding-top: 5px;
  font-size: 0.75rem;
}

.header-text {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.card-form {
  margin-top: 5rem;
  padding: 4rem 1rem 2rem 1rem;
  position: relative;
}
.card-form-login {
  padding-top: 3rem;
  margin-right: 5%;
  margin-left: 5%;
}
@media only screen and (min-width: 1024px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 20%;
    margin-right: 20%;
  }
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 8px;
  background-color: #e0e0e0;
  border: none;
  // padding: 0.5rem 1rem;
}

.is-invalid {
  border: 1px solid red !important;
}
.text-red {
  color: red;
}
</style>
