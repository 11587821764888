<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>

    <!-- Filter Modal -->
    <b-modal
      hide-header-close
      hide-header
      id="filter-modal"
      :cancel-title="$t('btn.clear')"
      @cancel="handleclear"
      :ok-title="$t('btn.conf')"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("category.main_assets") }}</label>
      <select class="form-control" v-model="filterOptions.role">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in position"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
      <label>{{ $t("filter.dep") }}</label>
      <select class="form-control" v-model="filterOptions.department">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in department"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
    </b-modal>

    <!-- navbar -->
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div style="padding: 0.4em">
        <SuccessDialog
          :msg="successMessage"
          v-show="showSuccess"
        ></SuccessDialog>
        <div class="tab-content" id="myTabContent">
          <div class="pt-4 pb-2 gradient-text">
            <h2 class="head-table-text">{{ $t("zone.name") }}</h2>
          </div>
        </div>
        <div class="mb-4">
          <b-row class="w-100 m-0">
            <b-col
              class="mb-2 mb-sm-0 d-flex justify-content-md-center"
              cols="12"
              md="2"
            >
              <button
                class="d-flex filter text-light p-2 m-0"
                style="border: none"
                type="button"
                @click="openModalAddMainAsset"
              >
                + {{ $t("btn.add_zone") }}
              </button>
            </b-col>
            <b-col class="d-flex" cols="10" md="9">
              <span
                class="filter-box p-2 d-flex mr-2"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px"
              >
                <img src="../../assets/search-white.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  border-radius: 0 10px 10px 0 !important;
                "
                :placeholder="$t('home.search')"
                v-debounce:700ms="searchByText"
                v-model="searchText"
              />
            </b-col>
            <!-- <b-col cols="2" md="1" class="d-flex align-items-center">
              <button
                class="filter"
                style="border: none"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                @click="(filterShow = !filterShow), (searchClick = false)"

                <img src="../../assets/filter-white.svg" width="20px" />
              </button>
            </b-col> -->
          </b-row>
        </div>

        <div class="main">
          <!-- Ag Grid User's Table -->
          <ManangeZoneTable
            :page="currentPage"
            :perPage="perPage"
            :dataTable="userSearchResult"
            ref="userTable"
          />
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalrows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <!-- Modal Delete zone -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div
                class="modal-content container-fluid pt-4 pb-2"
                style="border-radius: 10px"
              >
                <div class="row">
                  <img class="del-icon" src="../../assets/delete_bin.svg" />
                  <p>
                    {{ $t("zone.click_todel") }}
                  </p>
                </div>
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                  >
                    {{ $t("btn.canc") }}
                  </button>
                  <button
                    type="button"
                    class="confirm-btn col"
                    data-dismiss="modal"
                    @click="confirmDelete()"
                  >
                    {{ $t("btn.conf") }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Confirm User -->
          <div>
            <div
              class="modal fade"
              id="confirmLostModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div
                  class="modal-content container-fluid pt-4 pb-2"
                  style="border-radius: 10px"
                >
                  <div class="row">
                    <p>
                      {{ $t("count.press") }}
                      <b>{{ $t("btn.conf") }}</b>
                      {{ $t("count.verify_us") }} <br />
                      {{ this.data.name }} <br />
                      <b>{{ $t("home.pos") }}</b>
                      {{ this.data.role }}
                      <b>{{ $t("home.dep") }}</b>
                      {{ this.data.department }}
                    </p>
                  </div>
                  <div class="row">
                    <button
                      type="button"
                      class="btn cancle col"
                      data-dismiss="modal"
                    >
                      {{ $t("btn.canc") }}
                    </button>
                    <button
                      type="button"
                      class="confirm col"
                      data-dismiss="modal"
                      @click="confirmUser()"
                    >
                      {{ $t("btn.conf") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add ZOne -->
        <b-modal
          id="addZone"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-form-group>
            <h3 style="text-align: center">
              {{ $t("zone.add_zone") }}
            </h3></b-form-group
          >
          <b-row>
            <b-col cols="12 " class="d-flex pt-1 pb-3 flex-wrap">
              <div
                class="tag-email"
                v-for="(item, index) in emailArr"
                :key="index"
              >
                <p class="mb-0">{{ item.email }}</p>
                <span style="margin-left: 5%">
                  <button
                    class="
                      delete-tag
                      text-light
                      border-none
                      h-100
                      align-self-center
                    "
                    @click="removeTag(index)"
                  >
                    x
                  </button></span
                >
              </div></b-col
            >
          </b-row>

          <!-- modal add zone -->
          <form @submit.prevent="addZones">
            <b-row>
              <b-col cols="12">
                <b-form-group :state="state">
                  <b-input-group>
                    <b-form-input
                      v-model="addZone"
                      :placeholder="$t('zone.zone_sec')"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-3 flex justify-content-center text-center"
              >
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                    @click="$bvModal.hide('addZone')"
                  >
                    {{ $t("btn.canc") }}
                  </button>

                  <button
                    type="submit"
                    class="col"
                    data-dismiss="modal"
                    :class="[addZone.length < 1 ? 'btn-disabled' : 'confirm']"
                    :disabled="addZone.length < 1"
                  >
                    {{ $t("btn.save") }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-modal>

        <!-- Modal After Send Email -->
        <b-modal
          id="addUserResult"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-row>
            <b-col class="text-center" v-if="isSuccess">
              <img
                src="./../../assets/check-circle-fill.svg"
                width="102"
                height="102"
                alt=""
              />
              <p
                class="mt-3"
                style="color: #007afe; font-size: 23px; font-weight: normal"
              >
                {{ $t("dialog.invite_done") }}
              </p>
            </b-col>
            <b-col class="text-center" v-else>
              <img
                src="./../../assets/cancle.svg"
                width="102"
                height="102"
                alt=""
              />
              <p
                class="mt-3"
                style="color: #007afe; font-size: 23px; font-weight: normal"
              >
                {{ $t("dialog.invite_fail") }}
              </p>
            </b-col>
          </b-row>
        </b-modal>
      </div>

      <!-- Modal Edit User -->
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-2 pb-4"
            style="border-radius: 10px"
          >
            <div class="mt-3">
              <b-form-group>
                <h3 style="text-align: center">
                  {{ $t("zone.edit_zone") }}
                </h3></b-form-group
              >
              <b-row>
                <b-col cols="12 " class="d-flex pt-1 pb-3 flex-wrap">
                  <div
                    class="tag-email"
                    v-for="(item, index) in emailArr"
                    :key="index"
                  >
                    <p class="mb-0">{{ item.email }}</p>
                    <span style="margin-left: 5%">
                      <button
                        class="
                          delete-tag
                          text-light
                          border-none
                          h-100
                          align-self-center
                        "
                        @click="removeTag(index)"
                      >
                        x
                      </button></span
                    >
                  </div></b-col
                >
              </b-row>

              <!-- modal update zone -->
              <b-row>
                <b-col cols="12">
                  <b-form-group :state="state">
                    <b-input-group>
                      <b-form-input
                        v-model="updateZones"
                        :placeholder="$t('zone.zone_sec')"
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-4 flex justify-content-center text-center"
                >
                  <div class="row">
                    <button
                      type="button"
                      class="btn cancle col"
                      data-dismiss="modal"
                      @click="$bvModal.hide('addZone')"
                    >
                      {{ $t("btn.canc") }}
                    </button>
                    <button
                      type="button"
                      class="col"
                      data-dismiss="modal"
                      @click="updateZone()"
                      :disabled="updateZones.length < 1"
                      :class="[
                        updateZones.length < 1 ? 'btn-disabled' : 'confirm',
                      ]"
                    >
                      {{ $t("btn.conf") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import ManangeZoneTable from "@/components/Table/ManageZoneTable.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import Swal from "sweetalert2";
import SuccessDialog from "@/components/SuccessDialog.vue";

export default {
  data() {
    return {
      isLoading: true,
      showSuccess: false,
      successMessage: "",
      searchText: "",
      onClick: true,
      editShow: false,
      deleteShow: false,
      filterShow: false,
      searchClick: false,
      filterOptions: {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      },
      role_choice: [],
      index: "",
      data: {
        id: "",
        name: "",
        departmnet: "",
        role: "",
      },
      name: "editUserModal",
      position: [],
      department: [],
      selected: null,
      emailArr: [],
      email: null,
      isSuccess: false,
      addZone: "",
      del_idLocationZone: null,
      del_name: null,
      update_idzone: null,
      updateZones: "",
      currentPage: 1,
      perPage: 100,
      totalrows: 200,
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
    ManangeZoneTable,
    SuccessDialog,
  },
  async mounted() {
    this.searchClick = true;
    this.loadAllZone();
    const cookieData = JSON.parse(Cookie.get("selectedcompany"));
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
      getAllUserDetail: "getAllUserDetail",
      getUserDetail: "getUserDetail",
      allZone: "allzone",
      canUseCustom7: "canUseCustom7",
    }),
    datatable() {
      let datafilter = this.getAllUserDetail.data.data;
      const filterOptions = this.filterOptions;
      const User = datafilter.filter((user) => {
        let searchRole =
          filterOptions.role == user.permission.display_name
            ? true
            : filterOptions.role == "ทั้งหมด"
            ? true
            : false;

        let searchDepartment =
          filterOptions.department == user.department
            ? true
            : filterOptions.department == "ทั้งหมด"
            ? true
            : false;
        return searchDepartment && searchRole;
      });
      return User;
    },
    userSearchResult() {
      if (this.getAllUserDetail) {
        let searchText = this.searchText.toLowerCase();
        // let getAllUserDetail = this.getAllUserDetail;
        // function search
        return this.getAllUserDetail.data.data.filter((user) => {
          this.role_choice.push(user.role);
          // console.log("this.role_choice", this.role_choice);

          // var variable = (condition) ? (true block) : ((condition2) ? (true block2) : (else block2))
          let searchName = user.user.name.toLowerCase().includes(searchText);

          let searchDepartment = false;
          let searchRole = false;

          if (this.filterOptions.department == user.department) {
            searchDepartment = true;
          } else if (this.filterOptions.department == "ทั้งหมด") {
            searchDepartment = true;
          }
          if (this.filterOptions.role == user.permission.display_name) {
            searchRole = true;
          }
          if (this.filterOptions.role == "ทั้งหมด") {
            searchRole = true;
          }

          //   this.filterOptions.department == user.department
          //     ? true
          //     : this.filterOptions.department == "ทั้งหมด"
          //     ? true
          //     : false;
          this.searchClick = false;
          return searchName && searchDepartment && searchRole;
        });
      } else {
        return null;
      }
    },
    roleFilterArr() {
      let choice = [...new Set(this.role_choice)];
      // console.log("filter role",choice.length,this.role_choice)

      return choice[0] === undefined ? ["ไม่พบข้อมูล"] : choice;
    },
    optionPosition() {
      return this.position
        .map((position) => {
          return position.text;
        })
        .filter((x) => x);
    },
    state() {
      // console.log("email", this.email?.length);
      return this.email?.length > 0 && this.email.includes("@");
    },
    // invalidFeedback() {
    //   return "กรุณากรอกอีเมลที่ต้องการเพิ่ม";
    // },
  },
  methods: {
    searchByText() {
      this.loadAllZone();
    },
    handleclear() {
      this.filterOptions = {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      };
    },
    deleteUser() {
      this.$refs.userTable.getSelectedRowData();
    },
    async openModalAddMainAsset() {
      await this.$bvModal.show("addZone");
    },

    clickBox(id) {
      let box = document.getElementById(`box${id}`);
      box.click();
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoCatAssets() {
      this.$router.push("/admin/CatAssets");
    },
    gotoManageDepartments() {
      this.$router.push("/admin/ManageDepartment");
    },
    gotoManageZone() {
      this.$router.push("/admin/ManageZone");
    },
    showDelete(zone) {
      this.deleteShow = true;
      console.log("show zone-->", zone.id_location_zone);
      this.del_idLocationZone = zone.id_location_zone;
      this.del_name = zone.name;
    },
    showConfirm(user) {
      this.data.id = user.id_user;
      this.data.name = user.name;
      this.data.role = user.role;
      this.data.department = user.department;
    },
    cancleDelete() {
      this.deleteShow = false;
    },

    async confirmUser() {
      // Add console;

      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.$store.dispatch("confirmUser", {
        id_user: this.data.id,
        company_id: id_company,
      });
    },
    setFilter() {
      this.userSearchResult = this.userFilter;
    },
    resetFilter() {
      this.filterOptions.department = "ทั้งหมด";
      this.filterOptions.role = "ทั้งหมด";

      document.getElementById("search_button").click();
    },
    eventClick() {
      this.onClick = !this.onClick;
    },

    backToDetail() {
      this.editShow = false;
    },
    async addZones() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.addZone = this.addZone.trim();
      const check = await this.validateCustomInput();
      if (!check) return;
      if (this.addZone) {
        let data = {
          name: this.addZone,
          id_company: id_company,
        };
        this.isLoading = true;
        try {
          await this.$store.dispatch("addzone", data);
          this.successMessage = this.$t("dialog.add_success");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          // console.log("error addsub",e.response.data.message);
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_zone"),
              });
            }
          } else {
            console.log(e);
          }
        }
        console.log("add zone --> ", data);
        this.addZone = "";
        await this.loadAllZone();
        this.$store.dispatch("getzone", data);
        await this.$bvModal.hide("addZone");
        this.isLoading = false;
      }
    },
    async confirmDelete() {
      this.deleteShow = false;
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      let data = {
        name: this.del_name,
        id_company: id_company,
        id_location_zone: this.del_idLocationZone,
      };
      console.log("del data -->", data);
      try {
        await this.$store.dispatch("deletezone", data);
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      } catch (err) {
        console.log(err);
      }
      await this.loadAllZone();
      this.$store.dispatch("getzone", data);
      await this.$bvModal.hide("addZone");
      this.isLoading = false;
    },
    async goToDetail(zone_id) {
      // console.log("goto zone -->", zone_id);
      this.update_idzone = zone_id;
      this.updateZones = this.allZone.find(
        (zone) => zone.id_location_zone == zone_id
      ).name;
      console.log("edit name zone --> ", zone_id);
    },
    async validateCustomInput() {
      if (this.canUseCustom7 && !this.updateZones.includes(" - ")) {
        const result = await Swal.fire({
          icon: "error",
          text: `${this.$t("home.zone")} ${this.$t(
            "dialog.incorrect_confirm"
          )}`,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          cancelButtonText: this.$t("btn.canc"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          reverseButtons: true,
        });
        return result.isConfirmed;
      } else {
        return true;
      }
    },
    async updateZone() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.updateZones = this.updateZones.trim();
      const check = await this.validateCustomInput();
      if (!check) return;
      if (this.updateZones) {
        let data = {
          name: this.updateZones,
          id_company: id_company,
          id_location_zone: this.update_idzone,
        };
        this.isLoading = true;
        try {
          await this.$store.dispatch("updatezone", data);
          this.successMessage = this.$t("dialog.save_edit");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          // console.log("error addsub",e.response.data.message);
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_zone"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.isLoading = false;
        await this.loadAllZone();
        this.$store.dispatch("getzone", data);
      }
    },
    async loadAllZone() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.totalrows = await this.$store.dispatch("getallzone", {
        id_company: id_company,
        currentPage: this.currentPage,
        perPage: this.perPage,
        searchText: this.searchText,
      });
      this.isLoading = false;
    },
  },
  watch: {
    currentPage() {
      this.loadAllZone();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}
.tag-email {
  display: flex;
  // height: 30px;  font-size: 14px;

  color: #000000;
  background: #c7c7c7;
  width: fit-content;
  border-radius: 15px;

  padding: 0.25em 1.25em 0.25em 1em;
  margin: 0.25em 0.4em;
}
.delete-tag {
  width: 20px !important;
  height: 20px !important;
  font-size: 13px !important;
  border-radius: 50%;
  background: #7e7e7e;
  margin: 0px !important;
  border: none;
  color: #c7c7c7 !important;
  cursor: pointer;
}

.main {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  thead th:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }
  tbody td {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  tbody td:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }

  tbody {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  //  table scrollable
  .main {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    tbody td {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    thead th:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }
    tbody td:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.btn-disabled {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #cecece 0%, #7c7c7c 100%) 0%
    0% no-repeat padding-box;
  cursor: not-allowed !important;
}

.confirm-btn {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #fe0000 0%, #810101 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content !important;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
// .modal-content {
//   // padding: 1rem;
//   border-radius: 35px;
//   height: auto;

//   & label {
//     color: #4b4b4b;
//     padding: 0.4rem;
//   }
// }

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
