<template>
  <div>
    <div v-if="customid === 2">
      <div class="input-group">
        <label>{{ $t("asset.region_province") }}</label>
        <select
          class="form-control w-100"
          v-model="customData.sector"
          :disabled="disable"
        >
          <option :value="null">{{ $t("home.not_specified") }}</option>
          <option
            v-for="sector in options.optionSector"
            :value="sector.sector_id"
            :key="sector.sector_id"
          >
            {{ sector.name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label>{{ $t("asset.branch") }}</label>
        <select
          class="form-control w-100"
          v-model="customData.branch"
          :disabled="disable"
        >
          <option :value="null">{{ $t("home.not_specified") }}</option>
          <option
            v-for="branch in optionBranch"
            :value="branch.branch_id"
            :key="branch.branch_id"
            >{{ branch.name }}</option
          >
        </select>
      </div>
      <div class="input-group">
        <label>{{ $t("asset.insurance_start") }}</label>
        <date-picker
          class="inline-block h-full"
          locale="en"
          v-model="customData.custom_start_time"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <b-row>
              <b-col cols="3" class="flex items-center m-0 pe-0">
                <b-button
                  :disabled="disable"
                  variant="outline-primary"
                  class="text-white"
                  style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                  type="button"
                  @click="togglePopover()"
                >
                  <b-icon icon="calendar"></b-icon>
                </b-button>
              </b-col>
              <b-col cols="9" class="flex items-center h-100 ps-0">
                <input
                  :disabled="disable"
                  :value="inputValue | formatDate"
                  class="form-control"
                  readonly
                  @click="togglePopover()"
                />
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </div>
      <div class="input-group">
        <label>{{ $t("asset.insurance_end") }}</label>
        <date-picker
          class="inline-block h-full"
          locale="en"
          v-model="customData.custom_end_time"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <b-row>
              <b-col cols="3" class="flex items-center m-0 pe-0">
                <b-button
                  :disabled="disable"
                  variant="outline-primary"
                  class="text-white"
                  style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                  type="button"
                  @click="togglePopover()"
                >
                  <b-icon icon="calendar"></b-icon>
                </b-button>
              </b-col>
              <b-col cols="9" class="flex items-center h-100 ps-0">
                <input
                  :disabled="disable"
                  :value="inputValue | formatDate"
                  class="form-control"
                  readonly
                  @click="togglePopover()"
                />
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </div>
      <div class="input-group">
        <label>{{ $t("asset.vendor") }}</label>
        <select
          class="form-control w-100"
          v-model="customData.vendor"
          :disabled="disable"
        >
          <option :value="null">{{ $t("home.not_specified") }}</option>
          <option
            v-for="vendor in options.optionVendor"
            :value="vendor.vendor_id"
            :key="vendor.vendor_id"
            >{{ vendor.name }}</option
          >
        </select>
      </div>
    </div>
    <div v-if="customid === 3">
      <div class="input-group">
        <label>{{ $t("asset.intendant") }}</label>
        <input type="text" v-model="customData.intendant" :disabled="disable" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  components: {
    DatePicker,
  },
  emits: ["set-custom-data"],
  props: {
    customid: {
      type: Number,
      required: true,
    },
    data: {
      required: true,
      default: {},
    },
    disable: {
      type: Boolean,
      default: false,
      required: false,
    },
    options: {
      default: {},
      required: true,
    },
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  data() {
    return {
      customData: {
        sector: null,
        branch: null,
        custom_start_time: null,
        custom_end_time: null,
        vendor: null,
        intendant: "",
      },
    };
  },
  watch: {
    "customData.sector"(_, oldItem) {
      if (oldItem) {
        this.customData.branch = null;
      }
    },
    data: {
      handler() {
        if (this.$route.name === "EditDetailAsset") {
          this.customData.sector = this.data.sector_id || null;
          this.customData.branch = this.data.branch_id || null;
          this.customData.custom_start_time =
            this.data.custom_start_time || null;
          this.customData.custom_end_time = this.data.custom_end_time || null;
          this.customData.vendor = this.data.vendor_id || null;
          this.customData.intendant = this.data.intendant || null;
          console.log(this.customData);
        } else {
          this.customData.sector = this.data.sector || null;
          this.customData.branch = this.data.branch || null;
          this.customData.custom_start_time =
            this.data.custom_start_time || null;
          this.customData.custom_end_time = this.data.custom_end_time || null;
          this.customData.vendor = this.data.vendor || null;
          this.customData.intendant = this.data.intendant || null;
        }
      },
      deep: true,
    },
    customData: {
      handler() {
        if (this.$route.name === "EditDetailAsset") {
          this.$emit("set-custom-data", {
            sector_id: this.customData.sector,
            branch_id: this.customData.branch,
            custom_start_time: this.customData.custom_start_time,
            custom_end_time: this.customData.custom_end_time,
            vendor_id: this.customData.vendor,
            intendant: this.customData.intendant,
          });
        } else {
          this.$emit("set-custom-data", this.customData);
        }
      },
      deep: true,
    },
  },
  computed: {
    optionBranch() {
      const branch = this.options.optionBranch?.filter(
        (opt) => opt.sector_id === this.customData.sector
      );
      return branch || [];
    },
  },
  mounted() {
    if (this.$route.name === "EditDetailAsset") {
      this.customData.sector = this.data.sector_id || null;
      this.customData.branch = this.data.branch_id || null;
      this.customData.custom_start_time = this.data.custom_start_time || null;
      this.customData.custom_end_time = this.data.custom_end_time || null;
      this.customData.vendor = this.data.vendor_id || null;
      this.customData.intendant = this.data.intendant || null;
      console.log(this.customData);
    } else {
      this.customData.sector = this.data.sector || null;
      this.customData.branch = this.data.branch || null;
      this.customData.custom_start_time = this.data.custom_start_time || null;
      this.customData.custom_end_time = this.data.custom_end_time || null;
      this.customData.vendor = this.data.vendor || null;
      this.customData.intendant = this.data.intendant || null;
    }
  },
};
</script>

<style lang="scss" scoped>
select,
input {
  border-radius: 6px !important;
}
</style>
