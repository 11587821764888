<template>
  <div v-if="customid === 2">
    <!-- <div v-if="false"> -->
    <label class="mt-2">{{ $t("maintenance_plan.vendor") }}</label>

    <v-select
      class="dropdown-style"
      :clearable="false"
      label="label"
      :reduce="(option) => option.value"
      :options="optionVendor"
      v-model="customFilterOptions.vendor_id"
    />
    <label class="mt-2">{{ $t("asset.region_province") }}</label>
    <v-select
      label="label"
      :reduce="(option) => option.value"
      :options="optionSector"
      v-model="customFilterOptions.sector_id"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("asset.branch") }}</label>
    <v-select
      label="label"
      :reduce="(option) => option.value"
      :options="optionBranch"
      v-model="customFilterOptions.branch_id"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("asset.insurance_start") }}</label>
    <div class="d-flex align-items-center w-100">
      <input
        type="date"
        class="form-control form-border"
        v-model="customFilterOptions.custom_start_time_min"
      />

      <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

      <input
        type="date"
        class="form-control form-border"
        :min="customFilterOptions.custom_start_time_min"
        v-model="customFilterOptions.custom_start_time_max"
      />
    </div>
    <label class="mt-2">{{ $t("asset.insurance_end") }}</label>
    <div class="d-flex align-items-center w-100">
      <input
        type="date"
        class="form-control form-border"
        v-model="customFilterOptions.custom_end_time_min"
      />

      <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

      <input
        type="date"
        class="form-control form-border"
        :min="customFilterOptions.custom_end_time_min"
        v-model="customFilterOptions.custom_end_time_max"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["set-filter-option"],
  props: ["customid", "options", "filterOptions"],

  data() {
    return {
      customFilterOptions: {
        vendor_id: null,
        sector_id: null,
        branch_id: null,
        custom_start_time_min: null,
        custom_start_time_max: null,
        custom_end_time_min: null,
        custom_end_time_max: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllAssetsFilter: "getAllAssetsFilter",
      getPageState: "getPageState",
      getManageAssetFilter: "getManageAssetFilter",
    }),
    optionVendor() {
      const opt =
        this.options.optionVendor?.map((vendor) => {
          return {
            label: vendor.name,
            value: vendor.vendor_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionSector() {
      const opt =
        this.options.optionSector?.map((sector) => {
          return {
            label: sector.name,
            value: sector.sector_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionBranch() {
      const branch = this.options.optionBranch.filter((branch) => {
        return branch.sector_id === this.customFilterOptions.sector_id;
      });
      const opt =
        branch?.map((branch) => {
          return {
            label: branch.name,
            value: branch.branch_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
  },
  watch: {
    customFilterOptions: {
      handler() {
        this.$emit("set-filter-option", this.customFilterOptions);
      },
      deep: true,
    },
    "customFilterOptions.custom_start_time_min"() {
      if (
        this.customFilterOptions.custom_start_time_min >
        this.customFilterOptions.custom_start_time_max
      ) {
        this.customFilterOptions.custom_start_time_max = this.customFilterOptions.custom_start_time_min;
      }
    },
    "customFilterOptions.custom_end_time_min"() {
      if (
        this.customFilterOptions.custom_end_time_min >
        this.customFilterOptions.custom_end_time_max
      ) {
        this.customFilterOptions.custom_end_time_max = this.customFilterOptions.custom_end_time_min;
      }
    },
  },
  methods: {
    setFilterFromStore() {
      if (this.getPageState.pageName === "AllAssetListToFloor") {
        if (this.getAllAssetsFilter) {
          let filter = { ...this.getAllAssetsFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              vendor_id: filter.filterOptions.vendor_id || null,
              sector_id: filter.filterOptions.sector_id || null,
              branch_id: filter.filterOptions.branch_id || null,
              custom_start_time_min: filter.filterOptions.custom_start_time_min,
              custom_start_time_max: filter.filterOptions.custom_start_time_max,
              custom_end_time_min: filter.filterOptions.custom_end_time_min,
              custom_end_time_max: filter.filterOptions.custom_end_time_max,
            };
          }
        }
      }
      if (this.getPageState.pageName === "ManageAsset") {
        console.log("this.getManageAssetFilter:", this.getManageAssetFilter);
        if (this.getManageAssetFilter) {
          let filter = { ...this.getManageAssetFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              vendor_id: filter.filterOptions.vendor_id || null,
              sector_id: filter.filterOptions.sector_id || null,
              branch_id: filter.filterOptions.branch_id || null,
              custom_start_time_min: filter.filterOptions.custom_start_time_min,
              custom_start_time_max: filter.filterOptions.custom_start_time_max,
              custom_end_time_min: filter.filterOptions.custom_end_time_min,
              custom_end_time_max: filter.filterOptions.custom_end_time_max,
            };
          }
        }
      }
      if (this.filterOptions) {
        let filter = { ...this.filterOptions };

        if (filter) {
          this.customFilterOptions = {
            vendor_id: filter.vendor_id || null,
            sector_id: filter.sector_id || null,
            branch_id: filter.branch_id || null,
            custom_start_time_min: filter.custom_start_time_min,
            custom_start_time_max: filter.custom_start_time_max,
            custom_end_time_min: filter.custom_end_time_min,
            custom_end_time_max: filter.custom_end_time_max,
          };
        }
      }
    },
  },
  mounted() {
    this.setFilterFromStore();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__search {
    background-color: white !important;
  }
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
</style>
