<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="1"
      loader="dots"
    ></Loading>
    <b-modal
      id="editNameColumn"
      hide-header-close
      hide-footer
      hide-header
      centered
    >
      <b-row>
        <b-col cols="12 mt-2">
          <b-form-group>
            <h4 style="text-align: center">
              {{ $t("column.edit_name") }}
            </h4>
            <b-input-group>
              <b-form-input
                v-model="nameColumn"
                :placeholder="$t('column.column_name')"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-2 flex justify-content-center text-center">
          <div class="row">
            <button
              type="button"
              class="btn cancle col"
              data-dismiss="modal"
              @click="$bvModal.hide('editNameColumn')"
            >
              {{ $t("btn.canc") }}
            </button>
            <button
              type="button"
              class="confirm col"
              data-dismiss="modal"
              @click="updateNameColumn()"
            >
              {{ $t("btn.conf") }}
            </button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div class="pt-4 pb-2 gradient-text">
        <h2 class="head-table-text">{{ $t("asset.manage_column_name") }}</h2>
      </div>

      <div class="sub-menu">
        <div
          class="main d-flex justify-content-start justify-content-xl-center"
        >
          <AdminManageColumnTable :dataTable="allColumn" ref="assetstable" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AdminManageColumnTable from "@/components/Table/AdminManageColumnTable.vue";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      id_edit: null,
      nameColumn: "",
    };
  },
  components: {
    Loading,
    AdminSlideMenu,
    AdminMenuBar,
    AdminManageColumnTable,
  },
  async mounted() {
    this.isLoading = true;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.$store.dispatch("getAllColumn", { company_id: id_company });
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      allColumn: "getAllColumn",
    }),
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    goToDetail(id) {
      this.id_edit = id;
      this.nameColumn = this.allColumn.find(
        (column) => column.id_other_column == this.id_edit
      ).name;
      this.$bvModal.show("editNameColumn");
    },
    async updateNameColumn() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.nameColumn = this.nameColumn.trim();
      if (this.nameColumn) {
        let data = {
          name: this.nameColumn,
          id_company,
          id_other_column: this.id_edit,
        };
        try {
          await this.$store.dispatch("updateColumnName", data);
        } catch (e) {
          // console.log("error addsub",e.response.data.message);
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_columnname"),
              });
            }
          } else {
            console.log(e);
          }
        }
        await this.$store.dispatch("getAllColumn", { company_id: id_company });
        this.$bvModal.hide("editNameColumn");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
*::v-deep .modal-footer {
  border: none !important;
}

.main {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(80vw / 10);
    max-width: calc(80vw / 10);
  }
  thead th:last-child {
    min-width: calc(80vw / 10 + 16px);
    max-width: calc(80vw / 10 + 30px);
  }
  tbody td {
    min-width: calc(80vw / 10);
    max-width: calc(80vw / 10);
  }
  tbody td:last-child {
    min-width: calc(80vw / 10 + 16px);
    max-width: calc(80vw / 10 + 16px);
  }

  tbody {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  //  table scrollable
  .main {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(200vw / 10);
      max-width: calc(200vw / 10);
    }

    tbody td {
      min-width: calc(200vw / 10);
      max-width: calc(200vw / 10);
    }
    tbody td:last-child {
      min-width: calc(200vw / 10 + 32px);
      max-width: calc(200vw / 10 + 32px);
    }
    thead th:last-child {
      min-width: calc(200vw / 10 + 32px);
      max-width: calc(200vw / 10 + 32px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.modal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

select {
  color: #000;
  padding: 0.4rem !important;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
}

textarea {
  padding: 0.4rem;
  border: none;
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
  padding-bottom: 1rem;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: 1rem;
  // justify-content: start;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}
#basic-addon1:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}
.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
.table:last-child {
  border-radius: 30px 30px 0 0;
}

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: 580px;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    padding-top: 30px;
    height: 600px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.modal-content {
  // padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    // padding: 0.4rem;
  }
}

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    overflow-x: scroll;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 6px;
}

.input-group[data-v-659b5a2a]
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  /* border-radius: 6px; */
  border: 0px;
  padding-left: 5px;
}

.decline-button {
  background: gray !important;
}

.confirm-button {
  background: #ed1616 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
</style>
