/* eslint-disable no-dupe-keys */
import Vue from "vue";
import VueI18n from "vue-i18n";
import thaiContent from "./th-TH";
import engContent from "./en-EN";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "th",
  lang: localStorage.getItem("locale") || "th",
  fallbackLocale: "th",
  // messages: { th: thContent, en: enContent },
  messages: {
    th: thaiContent,
    en: engContent,
  },
});

export default i18n;
