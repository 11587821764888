<template>
  <div v-if="customid === 3">
    <label class="mt-2">Last Verify</label>
    <div class="d-flex align-items-center w-100">
      <input
        type="date"
        class="form-control form-border"
        v-model="customFilterOptions.counting_time_min"
      />

      <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

      <input
        type="date"
        class="form-control form-border"
        :min="customFilterOptions.counting_time_min"
        v-model="customFilterOptions.counting_time_max"
      />
    </div>
    <label class="mt-2">Asset Holder</label>
    <v-select
      :options="optionIntendant"
      label="label"
      :reduce="(option) => option.value"
      v-model="customFilterOptions.intendant"
      class="dropdown-style"
      :clearable="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["set-filter-option"],
  props: ["customid", "options", "filterOptions"],

  data() {
    return {
      customFilterOptions: {
        counting_time_min: null,
        counting_time_max: null,
        intendant: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllAssetsFilter: "getAllAssetsFilter",
      getPageState: "getPageState",
      getManageAssetFilter: "getManageAssetFilter",
    }),
    optionIntendant() {
      if (this.options.optionsIntendant) {
        const intendantList = [
          { label: this.$t("filter.all"), value: null },
          ...this.options.optionsIntendant.map((item) => {
            return {
              label: item.intendant,
              value: item.intendant,
            };
          }),
        ];
        return intendantList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  watch: {
    customFilterOptions: {
      handler() {
        this.$emit("set-filter-option", this.customFilterOptions);
      },
      deep: true,
    },
    "customFilterOptions.counting_time_min"() {
      if (
        this.customFilterOptions.counting_time_min >
        this.customFilterOptions.counting_time_max
      ) {
        this.customFilterOptions.counting_time_max = this.customFilterOptions.counting_time_min;
      }
    },
  },
  methods: {
    setFilterFromStore() {
      if (this.getPageState.pageName === "AllAssetListToFloor") {
        if (this.getAllAssetsFilter) {
          let filter = { ...this.getAllAssetsFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              counting_time_min: filter.filterOptions.counting_time_min || null,
              counting_time_max: filter.filterOptions.counting_time_max || null,
              intendant: filter.filterOptions.intendant || null,
            };
          }
        }
      }
      if (this.getPageState.pageName === "ManageAsset") {
        if (this.getManageAssetFilter) {
          let filter = { ...this.getManageAssetFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              counting_time_min: filter.filterOptions.counting_time_min || null,
              counting_time_max: filter.filterOptions.counting_time_max || null,
              intendant: filter.filterOptions.intendant || null,
            };
          }
        }
      }
      if (this.filterOptions) {
        let filter = { ...this.filterOptions };

        if (filter) {
          this.customFilterOptions = {
            counting_time_min: filter.counting_time_min || null,
            counting_time_max: filter.counting_time_max || null,
            intendant: filter.intendant || null,
          };
        }
      }
    },
  },
  mounted() {
    this.setFilterFromStore();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__search {
    background-color: white !important;
  }
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
</style>
