<template>
  <div>
    <div v-if="customid == 2">
      <div class="text-start">
        <a @click="gotoManageBranch()">
          <span class="item-list">{{ $t("branch.name") }}</span>
        </a>
      </div>
      <br>
      <div class="text-start">
        <a @click="gotoManageVendor()">
          <span class="item-list">{{ $t("vendor.name") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    customid: Number,
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
  },
  mounted() {},
  methods: {
    gotoManageBranch() {
      this.$router.push("/admin/ManageBranch");
    },
    gotoManageVendor() {
      this.$router.push("/admin/ManageVendor");
    },
  },
};
</script>
<style lang="scss" scoped>
.item-list {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
</style>
