<template>
  <div v-if="showButton">
    <img
      @click="cancelButtonClicked"
      src="@/assets/Depreciation/cancel_action.svg"
      alt=""
      style="cursor: pointer;"
    />
  </div>
</template>

<script>
export default {
  methods: {
    cancelButtonClicked() {
      this.params.cancelActionDate(this.params.value.id_pause_asset);
    },
  },
  computed: {
    showButton() {
      const agParams = this.params.value;
      if (!agParams) {
        return false;
      }
      if (
        agParams.approve_activate_at &&
        !agParams.request_cancel_activate_at &&
        this.$route.name === "deactivate-asset"
      ) {
        return true;
      }
      if (
        agParams.approve_deactivate_at &&
        !agParams.request_cancel_deactivate_at &&
        this.$route.name === "activate-asset"
      ) {
        return true;
      }
      return true;
    },
  },
};
</script>
