<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <SuccessDialog
      :msg="$t('dialog.save_edit')"
      v-show="showSuccess"
    ></SuccessDialog>
    <!-- <Background></Background> -->
    <div class="scan-qr">
      <div class="back-icon">
        <img
          src="../assets/previous.svg"
          width="30"
          @click="gotoEditProfile()"
        />
      </div>

      <div class="changPassword">
        <h1 class="header-text">{{ $t("password.change_pw") }}</h1>

        <div class="card-form-login">
          <div class="input-group">
            <label
              >{{ $t("password.old_pw") }}<span class="require-red">*</span>
            </label>
            <div class="position-relative">
              <input
                :type="showOldPassword ? 'text' : 'password'"
                v-model="old_password"
              />
              <img
                v-if="showOldPassword"
                @click="showOldPassword = !showOldPassword"
                src="../assets/showpassword.png"
                alt="showpassword"
                class="password-toggler"
              />
              <img
                v-else
                @click="showOldPassword = !showOldPassword"
                src="../assets/hidepassword.png"
                alt="showpassword"
                class="password-toggler"
              />
            </div>
          </div>
          <div class="input-group">
            <label
              >{{ $t("password.new_pw") }}<span class="require-red">*</span>
            </label>
            <div class="position-relative">
              <input
                :type="showNewPassword ? 'text' : 'password'"
                v-model="new_password"
              />
              <img
                v-if="showNewPassword"
                @click="showNewPassword = !showNewPassword"
                src="../assets/showpassword.png"
                alt="showpassword"
                class="password-toggler"
              />
              <img
                v-else
                @click="showNewPassword = !showNewPassword"
                src="../assets/hidepassword.png"
                alt="showpassword"
                class="password-toggler"
              />
            </div>
            <span class="sub-text">{{ $t("password.pass8") }}</span>
          </div>
          <div class="input-group">
            <label
              >{{ $t("password.conf_new_pw")
              }}<span class="require-red">*</span>
            </label>
            <div class="position-relative">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="new_password_again"
              />
              <img
                v-if="showConfirmPassword"
                @click="showConfirmPassword = !showConfirmPassword"
                src="../assets/showpassword.png"
                alt="showpassword"
                class="password-toggler"
              />
              <img
                v-else
                @click="showConfirmPassword = !showConfirmPassword"
                src="../assets/hidepassword.png"
                alt="showpassword"
                class="password-toggler"
              />
            </div>
          </div>
          <button
            class="btn btn-email btn-auto"
            type="button"
            @click="changePassword()"
            :disabled="isLoading"
          >
            {{ $t("password.change_pw") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
// import Background from "@/components/Background.vue";

export default {
  data() {
    return {
      isLoading: false,
      showSuccess: false,
      error: "",
      old_password: "",
      new_password: "",
      new_password_again: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  components: { Footer, SuccessDialog },
 
  mounted() {
    // this.qrcode_id = this.$route.params.id;
  },
  methods: {
    gotoEditProfile() {
      this.$router.push("/edit_profile");
    },
    async changePassword() {
      this.isLoading = true;
      const strongPassword = new RegExp("(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})");
      if (
        this.old_password === "" &&
        this.new_password === "" &&
        this.new_password_again === ""
      ) {
        alert(this.$t("dialog.input_er"));
        this.isLoading = false;
        return;
      }
      if (this.new_password !== this.new_password_again) {
        alert(this.$t("dialog.incorrect_pw"));
        this.isLoading = false;
        return;
      }
      if (!strongPassword.test(this.new_password)) {
        alert(this.$t("dialog.pass8"));
        this.isLoading = false;
        return;
      }
      const data = {
        old_password: this.old_password,
        new_password: this.new_password,
        new_password_again: this.new_password_again,
      };
      try {
        await this.$store.dispatch("changePassword", data);
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
          this.isLoading = false;
          this.$router.back();
        }, 2000);
      } catch (err) {
        alert(err.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.changePassword {
  z-index: 100;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.sub-text {
  margin-top: 2px;
  text-align: left;
  font: normal normal normal 11px/18px Mitr;
  letter-spacing: 0px;
  color: #7c7c7c;
  opacity: 1;
  margin-right: auto;
}

input {
  padding-left: 15px;
  border: 0px;
  font-weight: bold;
}

.input-group {
  margin-bottom: 0.1rem;
  label {
    padding-bottom: 0.5rem;
  }
}

input {
  width: 100%;
  background: #e0e0e0;
  border-radius: 8px;
  border: 0px;
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  /* margin-left: -1px; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.btn-email {
  margin-bottom: 10rem;
  margin-top: 1rem;
  background: transparent linear-gradient(111deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.camera-frame-corner {
  z-index: 1000;
  width: 100px;
  height: 100px;

  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.sacn-qr {
  z-index: 100;
}

.detail {
  padding-top: 1rem;

  font-size: 11px;
  color: #7c7c7c;
}

.header-text {
  padding-top: 107px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.card-form-login {
  margin-top: 40px;
  margin-left: 25px;
  margin-right: 25px;
}

.banner {
  width: 40px;
  z-index: 10;
}

.camera-frame {
  height: 250px;
  width: 250px;
  border: 1px solid black;
  margin: auto;
}

// .box {
//   background-position: 0 0, 0 0, 100% 0, 0 100%;
//   background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
//   background-repeat: no-repeat;
// }
// .box-e {
//   // Cornered

//   background-image: linear-gradient(
//       0deg,
//       var(--clr-border),
//       var(--clr-border) 25%,
//       transparent 25%,
//       transparent 75%,
//       var(--clr-border) 75%
//     ),
//     // left
//       linear-gradient(
//         90deg,
//         var(--clr-border),
//         var(--clr-border) 25%,
//         transparent 25%,
//         transparent 75%,
//         var(--clr-border) 75%
//       ),
//     // top
//       linear-gradient(
//         180deg,
//         var(--clr-border),
//         var(--clr-border) 25%,
//         transparent 25%,
//         transparent 75%,
//         var(--clr-border) 75%
//       ),
//     // right
//       linear-gradient(
//         270deg,
//         var(--clr-border),
//         var(--clr-border) 25%,
//         transparent 25%,
//         transparent 75%,
//         var(--clr-border) 75%
//       );
//   // bottom;
// }

@media only screen and (max-width: 375px) {
  .camera-frame {
    height: 220px;
    width: 220px;
    border: 1px solid black;
    margin: auto;
    margin-top: 1rem;
  }

  // .card-form {
  //   width: 90%;
  //   height: auto;
  //   padding: 1.5rem;
  //   padding-bottom: 2rem;
  // }
}

@media only screen and (max-width: 320px) {
  .camera-frame {
    height: 200px;
    width: 200px;
    border: 1px solid black;
    margin: auto;
    margin-top: 1rem;
  }

  // .card-form {
  //   width: 90%;
  //   height: auto;
  //   padding: 1.5rem;
  //   padding-bottom: 2rem;
  // }
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
</style>
