export class RpcClient {
    stub;
    send;
    pendingCalls = new Map();
    constructor(send) {
        this.send = message => send(JSON.stringify(message));
        const callServer = this.callServer.bind(this);
        this.stub = new Proxy({}, {
            get(_target, prop, _receiver) {
                const methodName = prop;
                async function call(...args) {
                    return await callServer(methodName, args);
                }
                return call;
            },
        });
    }
    onReceive = (data) => {
        const message = JSON.parse(data);
        if (message.type === 'callResponse') {
            this.handleCallResponse(message);
        }
    };
    async callServer(methodName, args) {
        return await new Promise((resolve, reject) => {
            const id = `${Date.now()}-${Math.random()}`;
            const pendingCall = { resolve, reject };
            this.pendingCalls.set(id, pendingCall);
            const message = {
                type: 'call',
                id,
                methodName,
                args,
            };
            this.send(message);
        });
    }
    handleCallResponse(message) {
        const { id, result } = message;
        const pendingCall = this.pendingCalls.get(id);
        if (!pendingCall) {
            throw new Error(`No pending call with id ${id}`);
        }
        this.pendingCalls.delete(id);
        if (result.type === 'success') {
            pendingCall.resolve(result.data);
        }
        else {
            pendingCall.reject(result.error);
        }
    }
}
