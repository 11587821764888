<template>
  <div class="approve">
    <div class="bg">
      <img class="banner" src="../assets/Logo2.png" alt="" />
    </div>

    <div class="card-div">
      <!-- <img class="banner" src="../assets/Logo2.png" alt="" /> -->
      <div class="card-body">
        <!-- <h1 class="header-text">ล็อกอิน</h1> -->
        <img class="checker" src="../assets/check-3.svg" alt="" />
        <div class="upper">
          {{ $t("approve.rec_dt") }}<br />{{ $t("approve.rec_dt2") }}
        </div>
        <div class="lower">
          {{ $t("approve.conf_dt") }}<br />{{ $t("approve.conf_dt2") }}
        </div>

        <button class="btn btn-login" type="button" v-on:click="gotoLogin()">
          {{ $t("approve.back") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoLogin() {
      this.$store.commit("setRegisStatus", true);
      this.$router.go(-1); //login
    },
  },
};
</script>

<style lang="scss" scoped>
.approve {
  z-index: 0;
  position: relative;
}

.btn-login {
  padding: 0.55rem 1.75rem 0.55rem 1.75rem;
}

.card-body {
  margin: 15% 0%;
  padding: 0px;
}

.upper {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.lower {
  font-size: 18px;
  margin-bottom: 40px;
}

form {
  padding-bottom: 3rem;
}

.checker {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1025px) {
  .card-body {
    margin-top: 10%;
    margin-left: 30%;
    margin-right: 30%;
  }
}

.banner {
  width: 136px;
  height: auto;
  top: 0px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  z-index: 10;
}

.bg {
  /* position: fixed; */
  z-index: -10;
  margin: auto;
  width: 100%;
  height: 270px;
  /* border-radius: 1rem; */
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.body {
  position: fixed;
  z-index: -9;
  margin: auto;
  width: 100%;
  height: 50%;
}
</style>
