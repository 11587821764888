<template>
  <div class="responsive-bar">
    <AdminMenuBar />
    <div class="full-page">
      <!-- <AdminSlideMenu /> -->
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ menuText }}</div>
      </h1>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminMenuBar from "../../components/Depreciation/DepreciationMenubar.vue";
// import AdminSlideMenu from "../../views/Depreciation/components/DepreciationSlideMenu.vue";

export default {
  components: {
    AdminMenuBar,
    // AdminSlideMenu,
  },
  data() {
    return {};
  },
  methods: {
    gotoHome() {
      switch (this.$route.name) {
        case "manage-sub-financial-note":
          this.$router.push({ name: "manage-financial-note" });
          return;
        case "construction-history":
          this.$router.back();
          return;
        case "land-edit-log":
          this.$router.push({ name: "land-list" });
          return;
        case "land-log-detail":
          this.$router.push({ name: "land-edit-log" });
          return;
        default:
          this.$router.push({ name: "Home" });
          return;
      }
    },
  },
  computed: {
    menuText() {
      switch (this.$route.name) {
        case "depreciation-asset":
          return this.$t("depreciation.asset");
        case "deactivate-asset":
          return this.$t("depreciation.do_deactivate");
        case "activate-asset":
          return this.$t("depreciation.do_active");
        case "writeoff-asset":
          return this.$t("depreciation.do_writeoff");
        case "confirm-action":
          return this.$t("depreciation.confirm_action");
        case "depreciation-report":
          return this.$t("depreciation.depreciation_report");
        case "jv-document":
          return this.$t("depreciation.jv_document");
        case "paused-assets":
          return this.$t("depreciation.pause_asset");
        case "using-expired-asset":
          return this.$t("depreciation.asset_expired_use");
        case "sold-asset":
          return this.$t("depreciation.sold_asset");
        case "accounting-note":
          return this.$t("depreciation.financial_note");
        case "construction-asset":
          return this.$t("depreciation.building_asset");
        case "construction-history":
          return this.$t("depreciation.convert_history");
        case "manage-financial-note":
          return this.$t("depreciation.manage_financial_note");
        case "manage-sub-financial-note":
          return this.$t("depreciation.manage_financial_note");
        case "account-period":
          return this.$t("depreciation.account_period");
        case "land-list":
          return this.$t("depreciation.land");
        case "land-edit-log":
          return this.$t("depreciation.land_edit_log");
        case "land-log-detail":
          return this.$t("depreciation.land_edit_log");
        case "depreciation-status-history":
          return this.$t("depreciation.depreciation_history");
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @media only screen and (min-width: 1025px) {
//   .responsive-bar {
//     display: grid;
//     grid-template-columns: 250px calc(100vw - 250px);
//     grid-template-rows: minmax(100vh, auto);
//   }
// }
.full-page {
  width: 100%;
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 1025px) {
    display: none;
  }
}
</style>
