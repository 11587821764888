export const mutations = {
  setBorrowDate(state, payload) {
    state.borrowStartDateTime = payload.startDate;
    state.borrowEndDateTime = payload.endDate;
  },
  setSelectedAssetBorrow(state, payload) {
    state.borrowAssetList = payload;
  },
  removeSelectedAssetBorrow(state, payload) {
    state.borrowAssetList.delete(payload);
  },
  clearBorrowState(state) {
    state.borrowStartDateTime = null;
    state.borrowEndDateTime = null;
    state.borrowAssetList = new Map();
  },
  setBorrowOrderForm(state, payload) {
    const {
      borrowStartDateTime,
      borrowEndDateTime,
      note,
      refNo1,
      refNo2,
    } = payload;

    if (borrowStartDateTime) {
      state.borrowOrderForm.data.borrowStartDateTime = borrowStartDateTime;
    }
    if (borrowEndDateTime) {
      state.borrowOrderForm.data.borrowEndDateTime = borrowEndDateTime;
    }
    if (note) {
      state.borrowOrderForm.data.note = note;
    }
    if (refNo1) {
      state.borrowOrderForm.data.refNo1 = refNo1;
    }
    if (refNo2) {
      state.borrowOrderForm.data.refNo2 = refNo2;
    }
    state.borrowOrderForm.cached = true;
  },
  clearBorrowOrderForm(state) {
    state.borrowOrderForm.cached = false;
    state.borrowOrderForm.data.borrowStartDateTime = null;
    state.borrowOrderForm.data.borrowEndDateTime = null;
    state.borrowOrderForm.data.note = null;
    state.borrowOrderForm.data.refNo1 = null;
    state.borrowOrderForm.data.refNo2 = null;
  },
  setBorrowSettings(state, payload) {
    state.settings = payload;
  },
};
