<template>
  <!-- <a target="_blank" :href="params.value.data">
    <img :src="params.value.data" alt=""
  /></a> -->
  <ImagePreview
    v-if="imageUrl"
    class="image-preview-size"
    :src="imageUrl"
    height="100%"
    alt="Image"
    preview
  />
</template>

<script>
export default {
  computed: {
    imageUrl() {
      return this.$route.path.includes("borrowreturn")
        ? this.params.value
        : this.params.value.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.image-preview-size {
  height: 100%;
  width: auto;
  object-fit: contain;
}
</style>
