<template>
  <div v-if="customid === 4">
    <label class="mt-2">{{ $t("model.model") }}</label>
    <v-select
      :options="optionSubCategory2"
      label="label"
      :reduce="(option) => option.value"
      v-model="customFilterOptions.id_sub_category2"
      class="dropdown-style"
      :clearable="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["set-filter-option"],
  props: ["customid", "options", "filterOptions"],

  data() {
    return {
      customFilterOptions: {
        id_sub_category2: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllAssetsFilter: "getAllAssetsFilter",
      getPageState: "getPageState",
      getManageAssetFilter: "getManageAssetFilter",
    }),
    optionSubCategory2() {
      if (this.filterOptions.id_sub_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.options.optionSubCategory
            .find(
              (subCat) =>
                subCat.id_sub_category === this.filterOptions.id_sub_category
            )
            ?.sub_category2s.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category2,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }

      // console.log("?????", this.getAllAssetsStore[0]);
    },
  },
  watch: {
    customFilterOptions: {
      handler() {
        this.$emit("set-filter-option", this.customFilterOptions);
      },
      deep: true,
    },
  },
  methods: {
    setFilterFromStore() {
      if (this.getPageState.pageName === "AllAssetListToFloor") {
        if (this.getAllAssetsFilter) {
          let filter = { ...this.getAllAssetsFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              id_sub_category2: filter.filterOptions.id_sub_category2 || null,
            };
          }
        }
      }
      if (this.getPageState.pageName === "ManageAsset") {
        if (this.getManageAssetFilter) {
          let filter = { ...this.getManageAssetFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              id_sub_category2: filter.filterOptions.id_sub_category2 || null,
            };
          }
        }
      }
      if (this.filterOptions) {
        let filter = { ...this.filterOptions };

        if (filter) {
          this.customFilterOptions = {
            id_sub_category2: filter.id_sub_category2 || null,
          };
        }
      }
    },
  },
  mounted() {
    this.setFilterFromStore();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__search {
    background-color: white !important;
  }
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
</style>
