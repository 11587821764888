<template>
  <div v-if="checkEdit">
    <img
      style="cursor: pointer;"
      src="@/assets/edit_pencil.svg"
      @click="gotoDetail()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserRoles } from "@/constants/UserRoles";
export default {
  data() {
    return {
      id_user: 1,
    };
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      isRoleLower: "isRoleLower",
    }),
    checkEdit() {
      if (this.params.value.is_payer) {
        return false;
      } else if (this.params.value.id_user === this.id_user) {
        return false;
      }
      if (this.isRoleLower) {
        return false;
      }
      let roleUser = this.permissionStatus.permission.role.id_role;
      let roleData = this.params.data.edit.permission.role.id_role;
      if (roleUser === UserRoles.ACC_MANAGER || roleUser < roleData) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.id_user = await this.$store.dispatch("getCurrentIdUser");
  },
  methods: {
    gotoDetail() {
      this.$parent.$parent.$parent.showEditUserModal(this.params.value.id_user);
    },
  },
};
</script>

<style scoped></style>
