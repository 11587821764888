import { render, staticRenderFns } from "./iconDeleteUser.vue?vue&type=template&id=658157ba&scoped=true&"
import script from "./iconDeleteUser.vue?vue&type=script&lang=js&"
export * from "./iconDeleteUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658157ba",
  null
  
)

export default component.exports