<template>
  <div
    v-if="
      params.value == 'hide' ||
       isRoleLower
    "
  ></div>
  <div v-else>
    <img
      v-if="params.value && params.value.can_delete"
      style="cursor: pointer"
      data-toggle="modal"
      data-target="#exampleModal"
      @click="showDelete(params.value)"
      src="@/assets/delete_bin.svg"
    />
    <img class="disabledBin" v-else src="@/assets/delete_bin_disabled.svg" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      mainAssets: "category",
      isRoleLower:"isRoleLower"
    }),
    
  },
  methods: {
    async showDelete(item) {
      // console.log("param", user.id_user);
      // const id_user = await this.$store.dispatch("getCurrentIdUser");
      // this.$parent.$parent.$parent.goToDetail(user.id_user);
      this.$parent.$parent.$parent.showDelete(item);
    },
  },
};
</script>

<style scoped></style>
