<template>
  <div v-if="canUseCustom8">
    <!-- <pre>{{ options.optionFloor }}</pre> -->
    <label class="mt-2">{{ $t("home.room") }}</label>
    <v-select
      :options="optionRoom"
      label="label"
      :reduce="(option) => option.value"
      v-model="customFilterOptions.id_location_room"
      class="dropdown-style"
      :clearable="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["set-filter-option"],
  props: ["options", "filterOptions"],

  data() {
    return {
      customFilterOptions: {
        id_location_room: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllAssetsFilter: "getAllAssetsFilter",
      getPageState: "getPageState",
      getManageAssetFilter: "getManageAssetFilter",
      canUseCustom8: "canUseCustom8",
    }),

    optionRoom() {
      if (this.filterOptions.id_location_floor) {
        const roomList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.options?.optionRoom
              .filter(
                (item) =>
                  this.filterOptions.id_location_floor == item.id_location_floor
              )
              .map((each) => {
                return { label: each.name, value: each.id_location_room };
              })
          ),
        ];
        return roomList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  watch: {
    customFilterOptions: {
      handler() {
        this.$emit("set-filter-option", this.customFilterOptions);
      },
      deep: true,
    },
  },
  methods: {
    setFilterFromStore() {
      if (this.getPageState.pageName === "AllAssetListToFloor") {
        if (this.getAllAssetsFilter) {
          let filter = { ...this.getAllAssetsFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              id_location_room: filter.filterOptions.id_location_room || null,
            };
          }
        }
      }
      if (this.getPageState.pageName === "ManageAsset") {
        if (this.getManageAssetFilter) {
          let filter = { ...this.getManageAssetFilter };
          if (filter.filterOptions) {
            this.customFilterOptions = {
              id_location_room: filter.filterOptions.id_location_room || null,
            };
          }
        }
      }
      if (this.filterOptions) {
        let filter = { ...this.filterOptions };

        if (filter) {
          this.customFilterOptions = {
            id_location_room: filter.id_location_room || null,
          };
        }
      }
    },
  },
  mounted() {
    this.setFilterFromStore();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__search {
    background-color: white !important;
  }
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
</style>
