<template>
  <ag-grid-vue
    style="width: 100%; height: 450px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Cookie from "js-cookie";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditUser from "../Table/components/iconEditUser.vue";
import btnConfirmUser from "../Table/components/btnConfirmUser.vue";
import iconDeleteUser from "../Table/components/iconDeleteUser.vue";
export default {
  components: {
    AgGridVue,
    iconEditUser,
    btnConfirmUser,
    iconDeleteUser,
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  props: { dataTable: { default: () => [] } },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 110,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("home.name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.email"),
        field: "email",
        sortable: true,
        minWidth: 220,
      },
      {
        headerName: this.$t("home.phonenum"),
        field: "phone",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.permission"),
        field: "role",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.pos"),
        field: "position",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.dep"),
        field: "department",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.email_re_stat"),
        field: "user_status",
        cellStyle: (params) => {
          if (params.value === this.$t("home.resp")) {
            return { color: "#00AB1B" };
          } else if (params.value === this.$t("home.wait_conf")) {
            return { color: "#CA9100" };
          } else if (params.value === this.$t("home.wait_canc")) {
            return { color: "#ED1616" };
          }
          return null;
        },
        suppressSizeToFit: true,
      },

      {
        headerName: this.$t("home.edit_dt"),
        field: "edit",
        cellRendererFramework: "iconEditUser",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
      // {
      //   headerName: this.$t('home.conf_us'),
      //   field: "confirm",
      //   cellRendererFramework: "btnConfirmUser",
      //   maxWidth: 170,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: this.$t("home.del_us"),
        field: "delete",
        cellRendererFramework: "iconDeleteUser",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
    ];
  },
  computed: {
    rowData() {
      console.log(this.dataTable, "dataTable");
      return this.dataTable.map((user, index) => {
        // if(user.user.email ===)
        // console.log(user);
        return {
          // index: index + 1,
          name: user.user.name,
          email: user.user.email,
          phone: user.user.phone,
          department: user.department ? user.department : "-",
          role: user.position,
          position: user.position_company ? user.position_company : "-",
          user_status:
            user.invite_status === "Confirm"
              ? this.$t("home.resp")
              : user.invite_status === "Invited"
              ? this.$t("home.wait_conf")
              : this.$t("home.wait_canc"),
          edit: user,
          delete:
            user.user.id_user === this.id_user
              ? "hide"
              : user.is_payer
              ? "hide"
              : user,
          confirm:
            user.is_confirm === "Confirm"
              ? { status: "Confirm", user }
              : user.is_confirm === "Not Confirm" &&
                user.invite_status === "Confirm"
              ? { status: "Invited", user }
              : { status: "Decline", user },
        };
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  mounted() {
    let cookie = Cookie.get("cookielogin");
    if (cookie) {
      let cookiejson = JSON.parse(cookie);
      this.id_user = cookiejson.id_user;
    }
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
