import moment from "moment";

export const state = {
  borrowStartDateTime: moment()
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString(),
  borrowEndDateTime: moment()
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(1, "days")
    .toISOString(),
  borrowAssetList: new Map(),
  borrowOrderForm: {
    cached: false,
    data: {
      borrowStartDateTime: null,
      borrowEndDateTime: null,
      note: null,
      refNo1: null,
      refNo2: null,
    },
  },
  settings: {
    is_borrow_return_scan: false,
    is_borrow_return_gap: false,
  },
};
