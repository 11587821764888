<template>
  <div v-if="params.value">
    <img src="@/assets/check_confirm.svg" />
  </div>
  <div v-else>-</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
export default {
  mounted() {},
  methods: {
    getparams() {}
  }
};
</script>

<style scoped>
.bin {
  margin-bottom: 13px;
}
</style>
