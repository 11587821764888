export const getters = {
  getBorrowDateTime: (state) => {
    if (state.borrowStartDateTime && state.borrowEndDateTime) {
      return {
        startDate: state.borrowStartDateTime,
        endDate: state.borrowEndDateTime,
      };
    }
    return null;
  },
  getBorrowAssetArray: (state) => {
    return Array.from(state.borrowAssetList, ([name, value]) => value);
  },
  getBorrowAsset: (state) => {
    return state.borrowAssetList;
  },
  getBorrowSettings: (state) => state.settings,
  getBorrowOrderForm: (state) => state.borrowOrderForm,
};
