<template>
  <div class="details mt-1" @click="gotoSubAssets()">
    <p>{{ $t("category.detail") }}</p>
    <!-- {{this.params.value}} -->
  </div>
</template>

<script>
export default {
  async mounted() {
    // this.id_user = await this.$store.dispatch("getCurrentIdUser");
    // console.log(this.id_user == this.params.value.id_user);
  },
  methods: {
    gotoSubAssets() {
      if (this.$route.name === "AdminCatAssets") {
        this.$router.push({
          name: "CatSubAssets",
          params: { id_category: this.params.value },
        });
        return;
      }
      if (this.$route.name === "CatSubAssets") {
        this.$router.push({
          name: "AssetModel",
          params: { id_subcategory: this.params.value },
        });
        return;
      }
      if (this.$route.name === "manage-financial-note") {
        this.$router.push({
          name: "manage-sub-financial-note",
          params: { note_accounting_id: this.params.value },
        });
        return;
      }
      if (this.$route.name === "ManageBranch") {
        this.$router.push({
          name: "ManageBranchOnLocation",
          params: { sector_id: this.params.value },
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.details {
  background: #015fc3;
  /* background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%); */
  color: white;
  border-radius: 15px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
</style>
