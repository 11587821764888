<template>
  <div class="responsive-bar">
    <AdminMenuBar v-show="shouldDisplayAdminMenuBar" />
    <div
      :style="{
        width: shouldDisplayAdminMenuBar ? '100%' : '100vw',
      }"
    >
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1
        class="header-text"
        :class="{ 'qr-page-header': $route.name === 'borrow-qr-scanner' }"
      >
        <div class="topic">{{ menuText }}</div>
      </h1>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMenuBar from "../BorrowReturn/BorrowMenubar.vue";

export default {
  components: {
    AdminMenuBar,
    // AdminSlideMenu,
  },
  data() {
    return {};
  },
  methods: {
    gotoHome() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters(["isBorrowReturnAdmin"]),
    shouldDisplayAdminMenuBar() {
      return !["borrow-qr-scanner"].includes(this.$route.name);
    },
    menuText() {
      switch (this.$route.name) {
        case "borrow-dashboard":
          return this.$t("borrow.dashboard");
        case "borrow-list":
          return this.$t("borrow.borrow_return_asset");
        case "manage-order":
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.manage_borrow_order");
          }
          return this.$t("borrow.my_borrow_return");
        case "borrow-history":
          return this.$t("borrow.borrow_return_history");
        case "borrow-setting":
          return this.$t("borrow.setting");
        case "add-borrow":
          return this.$t("borrow.add_borrow_return");
        case "remove-borrow":
          return this.$t("borrow.remove_borrow_asset");
        case "borrow-qr-scanner":
          return this.$t("scan.scanqr");
        case "borrow-order":
          return this.$t("borrow.create_borrow_order_banner");
        case "select-borrow":
          return this.$t("borrow.borrow_list");
        case "borrowable-asset":
          return "";
        default:
          return "";
      }
    },
  },
  async created() {
    if (this.isBorrowReturnAdmin) {
      await this.$store.dispatch("borrow/fetchBorrowSettings");
    }
  },
};
</script>

<style scoped lang="scss">
// @media only screen and (min-width: 1025px) {
//   .responsive-bar {
//     display: grid;
//     grid-template-columns: 250px calc(100vw - 250px);
//     grid-template-rows: minmax(100vh, auto);
//   }
// }
.qr-page-header {
  background: linear-gradient(180deg, #ba01fb 0%, #701078 100%);
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 1025px) {
    display: none;
  }
}
</style>
