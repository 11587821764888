<template>
  <div v-if="!beforeRefresh">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="responsive-bar">
      <Footer></Footer>
      <div class="profile">
        <!-- <Background></Background> -->
        <div class="">
          <h1 class="header-text">
            <div class="topic">{{ $t("profile.usinfo") }}</div>
          </h1>
          <div class="frame-profile">
            <img
              v-if="!getUserDetail.image_url"
              class="banner"
              width="500"
              src="../image/default-profile-icon.jpg"
              alt=""
            />
            <img v-else class="banner" :src="getUserDetail.image_url" alt="" />
          </div>

          <div class="">
            <!-- <div class="upload-icon-plus">
          <p class="upload-icon-content">+</p>
        </div> -->

            <div class="container profile-detail" style="margin-top: 80px">
              <img
                id="goEdit"
                src="../assets/pencil-square.svg"
                @click="gotoEditProfile()"
                style="cursor: pointer; align-item: right"
              />
              <div class="input-group">
                <label>{{ $t("profile.usname") }}</label>
                <p class="sub-label">{{ getUserDetail.name }}</p>
              </div>

              <div class="input-group">
                <label>{{ $t("profile.email") }}</label>
                <p class="sub-label">{{ getUserDetail.email }}</p>
              </div>

              <div class="input-group">
                <label>{{ $t("profile.phone") }}</label>
                <p class="sub-label">{{ getUserDetail.phone }}</p>
              </div>

              <div class="input-group">
                <label>{{ $t("profile.permission") }}</label>
                <p class="sub-label">{{ getUserDetail.role }}</p>
              </div>

              <div class="input-group">
                <label>{{ $t("profile.pos") }}</label>
                <p class="sub-label">{{ getUserDetail.position_company }}</p>
              </div>

              <div class="input-group" style="margin-bottom: 20px">
                <label>{{ $t("profile.dep") }}</label>
                <p class="sub-label">{{ getUserDetail.department }}</p>
              </div>
              <div
                v-if="
                  !(
                    permissionStatus.company.custom_id === 2 ||
                    getCustomName === 'jagota'
                  )
                "
                class="input-group"
              >
                <label>{{ $t("profile.lang") }}</label>
                <p class="sub-label language" style="color: #7c7c7c">
                  <span
                    :class="{ 'selected-language': $i18n.locale === 'th' }"
                    @click="switchLocale('th')"
                    >TH</span
                  >
                  |
                  <span
                    :class="{ 'selected-language': $i18n.locale === 'en' }"
                    @click="switchLocale('en')"
                    >EN</span
                  >
                </p>
              </div>
              <div v-if="featureFlags.offline" class="input-group">
                <label>{{ $t("profile.mode") }}</label>
                <v-select
                  v-model="mode"
                  :options="modeItems"
                  :reduce="(option) => option.value"
                  label="label"
                  class="dropdown-style"
                  :clearable="false"
                  :searchable="false"
                >
                  <template v-slot:option="option">
                    <div class="select-option">
                      <span
                        v-if="option.value === 'online'"
                        class="green-dot"
                      />
                      <span v-if="option.value === 'offline'" class="red-dot" />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
              <b-row
                align-v="center"
                align-h="center"
                style="margin-top: 20px;"
              >
                <b-col>
                  <button class="btn m-1" @click="goToSelectCompany()">
                    {{ $t("profile.ch_com") }}
                  </button>
                </b-col>
              </b-row>
            </div>
            <div style="margin-top: 30px">
              <hr style="margin: 0 25%" />
            </div>
            <div class="" style="margin-top: 50px">
              <div>
                <p style="text-align: center; font-size: 18px; color: #7c7c7c">
                  {{ $t("profile.contact") }}
                </p>
              </div>
              <div class="contact-detail">
                <div class="input-group">
                  <label>
                    <img
                      src="../assets/LineCircleIcon.svg"
                      style="width: 24px; height: 24px"
                    />
                  </label>
                  <p class="sub-label" style="margin-left: 5px; color: #2f6ed4">
                    @VERSCAN
                  </p>
                </div>
                <div class="input-group pt-0">
                  <label>
                    <img
                      src="../assets/PhoneCircleIcon.svg"
                      style="width: 24px; height: 24px"
                    />
                  </label>
                  <p class="sub-label" style="margin-left: 5px; color: #2f6ed4">
                    09 9414 6776
                  </p>
                </div>
                <div class="input-group pt-0">
                  <label>
                    <img
                      src="../assets/MailCircleIcon.svg"
                      style="width: 24px; height: 24px"
                    />
                  </label>
                  <p class="sub-label" style="margin-left: 5px; color: #2f6ed4">
                    service@verscan.com
                  </p>
                </div>
                <div class="input-group pt-0">
                  <label>
                    <img
                      src="../assets/FBCircleIcon.svg"
                      style="width: 24px; height: 24px"
                    />
                  </label>
                  <p class="sub-label" style="margin-left: 5px; color: #2f6ed4">
                    @houseofdevtech
                  </p>
                </div>
                <p>
                  {{ $t("profile.user_deletion") }}
                </p>
              </div>
            </div>
            <div>
              <div
                class="d-flex flex-wrap justify-content-center align-items-center"
              >
                <img
                  v-if="$i18n.locale === 'en'"
                  class="cursor-pointer d-block m-1"
                  style="height:45px"
                  @click="$router.push({ name: 'sponga-redirect' })"
                  src="../assets/Sponga/Sponga_Button_EN.png"
                  alt=""
                />
                <img
                  v-else
                  class="cursor-pointer d-block m-1"
                  style="height:45px"
                  @click="$router.push({ name: 'sponga-redirect' })"
                  src="../assets/Sponga/Sponga_Button.png"
                  alt=""
                />
                <button
                  class="btn btn-quit m-1"
                  type="submit"
                  @click="gotoLogin()"
                >
                  {{ $t("btn.logout") }}
                </button>
              </div>
              <p style="margin-top: 20px; color: #7c7c7c; font-size: 14px">
                Version : 1.2.0
              </p>
              <p style="margin-top: 20px; color: #fff; font-size: 14px">
                {{ url }}
              </p>
            </div>
          </div>
          <div class="line-break"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import { rpcClient } from "@/rpc/utils/rpcClient";
import { featureFlags } from "../native-app-feature-flag";

export default {
  data() {
    return {
      beforeRefresh: false,
      isLoading: false,
      showSucces: true,
      profile: {
        name: "",
        tel: "",
        department: "",
        position: "",
        email: "",
      },
      mode: "online",
      prevRoute: null,
      featureFlags,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      getCustomName: "getCustomName",
      canUseCustom5: "canUseCustom5",
    }),
    modeItems() {
      return [
        { value: "online", label: this.$t("profile.online") },
        { value: "offline", label: this.$t("profile.offline") },
      ];
    },
    url() {
      return window.location.hostname;
    },
  },
  methods: {
    switchLocale(lang) {
      this.$store.dispatch("switchLocale", lang);
    },
    goToSelectCompany() {
      this.$store.dispatch("goToSelectCompany", true);
    },
    goToManual() {},
    async gotoLogin() {
      this.isLoading = true;
      if (this.canUseCustom5) {
        this.beforeRefresh = true;
        this.$router.go();
        await this.$store.dispatch("logout");
      } else {
        await this.$store.dispatch("logout");
      }
      this.isLoading = false;
    },
    gotoEditProfile() {
      this.$router.push("/edit_profile");
    },
    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 2500);
    },
  },
  async mounted() {
    this.$store.dispatch("getUser");
    this.hide();
  },
  components: { Footer, Loading },
  watch: {
    mode(currentMode) {
      if (featureFlags.offline && currentMode === "offline") {
        rpcClient.stub.goToOfflineMode();
        this.mode = "online";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-edit {
  display: block;
  margin: auto;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: transparent linear-gradient(108deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.btn-manual {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  font-size: 12px !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.contact-detail {
  .sub-label {
    margin: auto;
    font-size: 16px;
    width: 50%;
  }

  label {
    font-size: 16px;
    margin-right: 20px;
    width: 25%;
    text-align: right;
    display: block;
  }
}

.profile-detail {
  .input-group {
    @media (min-width: 768px) {
      width: fit-content;
      margin-left: 25%;
    }
  }
  label {
    font-size: 16px;
    margin-right: 20px;
    text-align: left;
    display: block;
    min-width: 150px;
  }
  .sub-label {
    margin: auto;
    margin-right: 0;
    font-size: 16px;
    width: 250px;
  }
}
.language {
  span {
    cursor: pointer;
  }
  .selected-language {
    color: #2f6ed4;
  }
}

.button-grouping {
  display: flex;
  flex-direction: column;
  width: 144px;
  height: 43px;
  justify-content: center;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.frame-profile {
  position: absolute;
  // top: -0.2rem;
  top: 173px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  /* text-align: center; */
  margin: auto;
  background: white;
  border-radius: 50%;
  border: 5px solid white;
  // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
}
.card-form-profile {
  cursor: pointer;
  // display: grid;
  width: 80%;
  // grid-template-columns: auto 1fr;
  gap: 1rem;
  // padding: 1rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  // text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 50px;
    height: 50px;
  }
  h1 {
    font: normal normal normal 20px/18px Mitr;
  }
  &-custom {
    padding: 2rem 1.2rem 2rem 1.2rem;

    h1 {
      font: normal normal normal 27px/18px Mitr;
    }
  }
}
.card-form-contact {
  cursor: pointer;
  // display: grid;
  width: 80%;
  // grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  // text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 28px;
    height: 28px;
  }
  h1 {
    font: normal normal normal 20px/18px Mitr;
  }
  &-custom {
    padding: 2rem 1.2rem 2rem 1.2rem;

    h1 {
      font: normal normal normal 27px/18px Mitr;
    }
  }
}
.banner {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;

  z-index: 1;
}
.profile {
  position: relative;
  z-index: 2;
}

.btn-quit {
  background: $font-grey 0% 0% no-repeat padding-box;
  border-radius: 10px;
  // padding-left: 40px;
  // padding-right: 40px;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.input-group {
  display: flex;
  grid-template-columns: 1fr;
  padding: 1rem 1rem 1rem 1rem;
}

// .card-form {
//   margin-top: 5rem;
//   width: 80%;
//   padding: 4rem 1rem 2rem 1rem;
//   position: relative;
// }

#goEdit {
  height: 27px;
  width: 27px;
  float: right;
  // margin: 20px
  margin-right: 20px;
}

.dropdown-style {
  width: 120px;
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: #ed1616;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: #4dbc43;
  border-radius: 50%;
  display: inline-block;
}

.select-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
}
</style>
