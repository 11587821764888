<template>
  <div class="text-start" v-if="canUseCustom3">
    <div class="parent-asset">
      <p class="text-gray">{{ $t("subasset.parent_asset") }}</p>
      <AssetCard v-if="parentAsset" :asset="parentAsset"></AssetCard>
    </div>
    <div class="child-asset">
      <div class="d-flex justify-content-between text-gray">
        <p class="text-gray">
          {{ $t("subasset.child_asset") }}
        </p>
        <p>{{ subAsset.length }} {{ $t("subasset.item") }}</p>
      </div>
      <AssetCard
        v-for="asset in subAsset"
        :asset="asset"
        :key="asset.id_asset"
      ></AssetCard>
    </div>
  </div>
</template>

<script>
import AssetCard from "./AssetCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AssetCard,
  },
  computed: {
    ...mapGetters({
      getAsset: "getAsset",
      canUseCustom3: "canUseCustom3",
    }),
    parentAsset() {
      return this.getAsset?.asset.parent;
    },
    subAsset() {
      return this.getAsset?.asset.sub_assets || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-gray {
  color: #7c7c7c;
  font-size: 18px;
}
</style>
