<script setup>
import { computed, ref, watch } from "vue";
import store from "../store";

const mapUrl = computed(() => store.getters.getMapUrl);
const mapPosition = computed(() => store.getters.getMapPosition);

const hasValue = (x) => x !== null && x !== undefined;
const shouldDisplay = computed(() => {
  const { x, y } = mapPosition.value;
  return hasValue(x) && hasValue(y);
});

const scale = ref();
const image = ref();
watch(image, () => {
  const imageElement = image.value;
  if (!imageElement) return;
  const setScale = () => {
    const width = imageElement.clientWidth;
    const height = imageElement.clientHeight;
    const originalWidth = imageElement.naturalWidth;
    const originalHeight = imageElement.naturalHeight;
    // console.log("original", originalWidth, originalHeight);

    scale.value =
      width > height ? width / originalWidth : height / originalHeight;
    // console.log("scale", width, originalWidth, scale.value);
  };
  imageElement.onload = setScale;
  window.addEventListener("resize", setScale);
});
</script>
<template>
  <div
    class="border d-flex justify-content-center"
    :class="$attrs.class"
  >
    <div class="position-relative">
      <svg
        v-if="shouldDisplay"
        class="position-absolute z-30"
        height="12"
        width="12"
        :style="{
          transform: `translate(-50%, -50%) translate(${mapPosition.x *
            scale}px, ${mapPosition.y * scale}px)`,
        }"
      >
        <circle
          cx="6"
          cy="6"
          r="5"
          stroke-width="1"
          stroke="black"
          fill="red"
        />
      </svg>
      <img ref="image" :src="mapUrl" class="map-image" />
    </div>
  </div>
</template>
<style>
.map-image {
  width: fit-content;
  max-width: 100%;
  max-height: 300px;
}
</style>
