<template>
  <div>
    <div class="position-absolute" @click="toggleMenu" v-if="isSlide">
      <div class="burger-button">
        <span class="burger-bars line-style" style="top: 0%;"></span>
        <span class="burger-bars line-style" style="top: 40%;"></span>
        <span class="burger-bars line-style" style="top: 80%;"></span>
      </div>
    </div>
    <transition name="slide-in">
      <div
        class="responsive-bar-admin"
        v-if="showMenu"
        v-click-outside="toggleMenu"
      >
        <div
          @click="gotoHome()"
          class="responsive-bar-admin-custom"
          style="cursor: pointer"
        >
          <img src="@/assets/Logo2.png" width="60px" height="60px" />
        </div>
        <div class="responsive-bar-admin-menu my-3">
          <div
            class="menu-item"
            @click="$router.push({ name: 'borrow-dashboard' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'borrow-dashboard',
              }"
            >
              {{ $t("borrow.dashboard") }}
            </p>
          </div>
          <div class="menu-item" @click="$router.push({ name: 'borrow-list' })">
            <p
              :class="{
                'menu-selected': $route.name === 'borrow-list',
              }"
            >
              {{ $t("borrow.borrow_return_asset") }}
            </p>
          </div>
          <div
            class="menu-item"
            @click="$router.push({ name: 'manage-order' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'manage-order',
              }"
            >
              {{
                isBorrowReturnAdmin
                  ? $t("borrow.manage_borrow_order")
                  : $t("borrow.my_borrow_return")
              }}
            </p>
          </div>
          <div
            class="menu-item"
            @click="$router.push({ name: 'borrow-history' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'borrow-history',
              }"
            >
              {{ $t("borrow.borrow_return_history") }}
            </p>
          </div>
          <div
            v-show="isBorrowReturnAdmin"
            class="menu-item"
            @click="$router.push({ name: 'borrow-setting' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'borrow-setting',
              }"
            >
              {{ $t("borrow.setting") }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showMenu: false,
      showDepreciationAction: false,
      showMonthlyDepreciation: false,
      showAccReport: false,
      showOther: false,
      showSetting: false,
      windowWidth: 0,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  computed: {
    ...mapGetters({
      haveDpNotification: "haveDpNotification",
      permissionStatus: "permissionStatus",
      isBorrowReturnAdmin: "isBorrowReturnAdmin",
    }),

    isSlide() {
      if (this.windowWidth <= 1024) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkScreenSize();
    const route = this.$route.name;
    if (
      route === "depreciation-asset" ||
      route === "activate-asset" ||
      route === "deactivate-asset" ||
      route === "writeoff-asset" ||
      route === "confirm-action"
    ) {
      this.showDepreciationAction = true;
    }
    if (route === "depreciation-report" || route === "jv-document") {
      this.showMonthlyDepreciation = true;
    }
    if (
      route === "paused-assets" ||
      route === "using-expired-asset" ||
      route === "sold-asset" ||
      route === "accounting-note"
    ) {
      this.showAccReport = true;
    }
    if (
      route === "account-period" ||
      route === "manage-financial-note" ||
      route === "manage-sub-financial-note"
    ) {
      this.showSetting = true;
    }
    if (
      route === "land-list" ||
      route === "construction-asset" ||
      route === "construction-history" ||
      route === "land-edit-log" ||
      route === "land-log-detail"
    ) {
      this.showOther = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    goToDepreciationAsset() {
      this.$router.push({ name: "depreciation-asset" });
    },
    toggleMenu() {
      if (this.isSlide) {
        this.showMenu = !this.showMenu;
      }
    },
    checkScreenSize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1024) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-bar-admin-menu {
  p {
    margin: 0;
    &.menu-selected {
      color: #007afe;
    }
  }
  cursor: pointer;
  padding: 0 !important;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  overflow-y: auto;
  height: 100%;
  user-select: none;
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  &-submenu {
    padding-left: 1rem;
    & div {
      padding-top: 1rem;
    }
  }
}

.menu-item {
  padding: 15px 10px;
}
.menu-dropdown {
  .main-dropdown {
    padding: 15px 10px;
    // background-color: #dfdfdf;
  }
  .dropdown-list {
    background-color: #f8f8f8;
    .menu-dropdown-item {
      padding: 15px 10px;
      p {
        // width: 100%;
        margin-left: 15px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    box-shadow: $shadow-default;
  }
}

/*
  Animation
*/

.burger-button {
  position: absolute;
  cursor: pointer;
  height: 30px;
  top: 35px;
  left: 15px;
  width: 36px;
}

.line-style {
  height: 20%;
  left: 0;
  position: absolute;
  right: 0;
}

.burger-bars {
  background-color: white !important;
}

@media only screen and (max-width: 1024px) {
  .slide-in-enter-active,
  .slide-in-leave-active {
    transform: translate(0, 0);
    transition: transform 0.5s;
  }
  .slide-in-enter,
  .slide-in-leave-to {
    transform: translate(-100%, 0);
    transition: transform 0.5s;
  }
}

.ping {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  box-sizing: content-box;
  background: #ed1616;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* animation: load 1.5s ease-out infinite; */
}
</style>
