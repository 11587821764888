<template>
  <div>
    <div v-if="canUseCustom1 || canUseCustom5">
      <div class="input-group">
        <div class="ms-4">
          <p class="text-detail">เพิ่มรหัสสินทรพย์ชั่วคราวอัตโนมัติ</p>
          <div class="d-flex">
            <button
              class="auto-btn"
              type="button"
              :class="{ selected: selectedButton === 'new' }"
              @click="setAutoID('new')"
            >
              Auto-New
            </button>
            <button
              class="auto-btn"
              type="button"
              :class="{ selected: selectedButton === 'old' }"
              @click="setAutoID('old')"
            >
              Auto-Old
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "base-converter";
import { mapGetters } from "vuex";
export default {
  props: ["customid", "assetFixedId"],
  data() {
    return {
      tmpNumber: null,
      selectedButton: null,
    };
  },
  computed: {
    ...mapGetters({
      canUseCustom1: "canUseCustom1",
      canUseCustom5: "canUseCustom5",
    }),
  },
  watch: {
    assetFixedId() {
      if (this.selectedButton && this.assetFixedId !== this.tmpNumber) {
        this.selectedButton = null;
      }
    },
  },
  methods: {
    setAutoID(status) {
      if (this.selectedButton === status) {
        this.selectedButton = null;
        this.$emit("set-asset-fixed-id", {
          id: "",
        });
      } else {
        const tmp = this.getTMPNumber(status);
        this.selectedButton = status;
        this.$emit("set-asset-fixed-id", {
          id: tmp,
        });
        this.tmpNumber = tmp;
      }
    },
    getTMPNumber(status) {
      if (status === "new") {
        const nowDate = new Date();
        let year = nowDate.getFullYear() + "";
        year = year.slice(2, 5);
        const number = base.decTo36(nowDate.getTime()).toUpperCase();
        return `TMPN${year}${number}`;
      } else {
        const nowDate = new Date();
        let year = nowDate.getFullYear() + "";
        year = year.slice(2, 5);
        const number = base.decTo36(nowDate.getTime()).toUpperCase();

        return `TMPO${year}${number}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  font-size: 16px;
  color: #7c7c7c;
  text-align: left;
}

.auto-btn {
  background-color: transparent;
  margin: 0 5px;
  padding: 3px 10px;
  color: #7c7c7c;
  border: 1px solid #7c7c7c;
  border-radius: 4px;

  &.selected {
    color: #fff;
    background-color: #007afe;
  }
}
</style>
