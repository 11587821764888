<template>
  <div>
    <div class="bg"></div>
    <div class="body"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.className.replace(/\bhome\b/g, "");
  }
};
</script>

<style scoped>
.bg {
  position: fixed;
  z-index: -10;
  margin: auto;
  width: 100%;
  height: 160px;
  /* border-radius: 1rem; */
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.body {
  position: fixed;
  z-index: -9;
  margin: auto;
  width: 100%;
  height: 50%;
}
</style>
