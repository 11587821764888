<template>
  <div class="maintenance-data-list">
    <div>
      {{ $t("maintenance_report.reportitem")
      }}<span style="color: red;" v-if="!recheckData.recheck && detail">*</span>
    </div>
    <div v-if="assetData" class="selected-asset-thumbnail d-flex ">
      <a
        v-if="
          $route.name !== 'DetailOneAsset' && !recheckData.recheck && detail
        "
        @click="addRepairAssets()"
        class="change-button"
      >
        {{ $t("maintenance_report.change") }}</a
      >
      <div class="thumbnail-image">
        <img
          :src="assetImage(assetData.image_url)"
          width="100px"
          class="image-thumbnail"
        />
      </div>
      <div class="thumbmail-text d-flex flex-column justify-content-center">
        <p>{{ assetData.name }}</p>
        <p style="color: #818181;">
          {{ $t("maintenance_report.assetID") }}
          <span>{{ assetData.id_fixed_asset }}</span>
        </p>
        <div
          class="to-detail-asset"
          v-if="!detail"
          @click="toDetail(assetData.id_asset)"
        >
        <img src="@/assets/Maintenancing/to-detail-arrow.svg" width="10px">
        </div>
      </div>
    </div>
    <button class="maintenance-button" v-else @click="addRepairAssets()">
      <span style="font-size: 20px; font-weight: 300;">+</span>
      {{ $t("maintenance_report.asset") }}
    </button>
  </div>
</template>
<script>
import logo from "@/assets/Logo2.png";

export default {
  props: {
    assetData: {
      type: Object,
      default: null,
    },
    recheckData: {
      type: Object,
      default: () => ({
        recheck: false,
      }),
    },
    detail: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    assetImage(a) {
      return a == null ? logo : a.url;
    },
    addRepairAssets() {
      this.$router.push({
        name: "select_asset_maintenance",
        query: { callback: this.$route.name },
      });
    },
    toDetail(a) {
      this.$router.push("/detailOneAsset/" + a);
    },
  },
};
</script>
<style lang="scss" scoped>
.maintenance-data-list {
  padding: 3px 3px 10px 3px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 12px;
}
.maintenance-button {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 1px;
  border-radius: 11px;
}
.selected-asset-thumbnail {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;
}
.thumbmail-text {
  font-size: 16px;
}
.to-detail-asset {
  position: absolute;
  right: 5%;
  top: 35%;
}
.thumbnail-image {
  width: 80px;
  height: 80px;
  margin: 10px 10px;
}
.image-thumbnail {
  object-fit: cover;
  object-position: center;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.change-button {
  font-size: 14px;
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #007afe;
  text-decoration: underline;
  cursor: pointer;
}

.change-button:hover {
  color: #007afe;
  text-decoration: underline;
}
</style>
