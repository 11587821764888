<template>
  <div>
    <div v-if="customid === 1">
      <div
        class="input-group"
        style="display: flex; justify-content: center; padding: 0px"
      >
        <!-- Please add .json file _en/_th -->
        <label>{{ $t("asset.fixed_asset_for_vendor") }} :</label>
        <p class="sub-label" style="padding-left: 10px; margin-bottom: 0px">
          {{
            getDetailAsset.asset.asset_details[1]
              ? getDetailAsset.asset.asset_details[1].value
              : "-"
          }}
        </p>
        <p class="sub-label" style="padding-left: 10px; margin-bottom: 0px">
          <!-- {{ $t("detailasset.not_reg") }} -->
        </p>
      </div>
      <div
        class="input-group"
        style="
          display: flex;
          justify-content: center;
          padding: 0px;
          margin-bottom: 10px;
        "
      >
        <label>{{ $t("asset.fixed_asset_for_property") }} :</label>
        <p class="sub-label" style="padding-left: 10px">
          {{
            getDetailAsset.asset.asset_details[2]
              ? getDetailAsset.asset.asset_details[2].value
              : "-"
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    customid: Number,
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      getDetailAsset: "getDetailAsset",
    }),
  },
  mounted() {},
  methods: {},
};
</script>
