<template>
  <div v-if="checkEdit">
    <img
      v-if="$route.name === 'ManageVendor'"
      style="cursor: pointer;"
      src="@/assets/edit_pencil.svg"
      @click="showEditVendor"
    />
    <img
      v-else-if="
        $route.name === 'manage-financial-note' ||
          $route.name === 'manage-sub-financial-note'
      "
      style="cursor: pointer;"
      src="@/assets/edit_pencil.svg"
      @click="showEditFinancialNote"
    />
    <img
      v-else-if="$route.name === 'AssetModel'"
      style="cursor: pointer;"
      data-toggle="modal"
      data-target="#editModal"
      src="@/assets/edit_pencil.svg"
      @click="showEditModelAsset"
    />
    <img
      v-else
      style="cursor: pointer;"
      src="@/assets/edit_pencil.svg"
      data-toggle="modal"
      data-target="#editModal"
      @click="gotoDetail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      id_user: 1,
    };
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      isRoleLower: "isRoleLower",
    }),
    checkEdit() {
      if (this.isRoleLower) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.id_user = await this.$store.dispatch("getCurrentIdUser");
  },
  methods: {
    gotoDetail() {
      this.$parent.$parent.$parent.goToDetail(this.params.value.id_user);
    },
    showEditVendor() {
      this.$bvModal.show("editVendor");
      this.$parent.$parent.$parent.setEditVendor(this.params.value.id_user);
    },
    showEditFinancialNote() {
      this.$bvModal.show("editFinancialNote");
      this.$parent.$parent.$parent.setEditNoteAcc(this.params.value.id_user);
    },
    showEditModelAsset() {
      this.$parent.$parent.$parent.setModelEdit(this.params.value);
    },
  },
};
</script>

<style scoped></style>
