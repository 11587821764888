<template>
  <div>
    <div v-if="customid === 1">
      <div class="lightblue">
        <div class="alignment">
          <div class="labeltext">
            <label>{{ $t("detailasset.dep") }} :</label>
            <p class="sub-label" style="padding-left: 10px; margin-bottom: 0px">
              {{ department || "-" }}
            </p>
          </div>
          <!-- <div class="labeltext">
            <label>รหัสแผนก :</label>
            <p class="sub-label" style="padding-left: 10px; margin-bottom: 0px">
              {{ other1 }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    customid: Number,
    department: String,
    other1: String,
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.lightblue {
  padding: 0.5rem;
  background-color: #d6e7ff;
}
.labeltext {
  margin: auto;
  justify-content: center;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media screen and (min-width: 426px) {
  .labeltext {
    display: flex;
    align-items: center;
  }
}
</style>
