<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 100%;"
  >
    <button class="btn-bg bg-change" v-if="params.value == 'change'">
      <p style="line-height: 2.1;">{{ $t("btn.edit") }}</p>
    </button>
    <button class="btn-bg bg-new" v-if="params.value == 'new'">
      <p style="line-height: 2.1;">{{ $t("btn.new") }}</p>
    </button>
    <button class="btn-bg bg-danger" v-if="params.value == 'wrongData'">
      <p style="line-height: 2.1;">{{ $t("btn.wrong") }}</p>
    </button>
    <button class="btn-bg bg-danger" v-if="params.value == 'error'">
      <p style="line-height: 2.1;">{{ $t("btn.error") }}</p>
    </button>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log("params", this.params.value);
  },
  methods: {},
};
</script>

<style scoped>
.btn-bg {
  width: 80px;
  height: 25px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  border: none;
}
.bg-change {
  background: #ee6b0c;
}
.bg-new {
  background: #007afe;
}
</style>
