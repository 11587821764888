<template>
  <div class="bin-icon" v-if="params.data.asset_status !== this.$t('count.not_reg')" @click="goToDetail">
    <img style="cursor: pointer" src="@/assets/edit_pencil.svg" />
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log("params", this.params.data.asset_status);
  },
  methods: {
    // async goToDetail() {
    //   this.$store.commit("setMapUrlMutations", "");
    //   this.$store.commit("setMapPositionMutations", "");
    //   await this.$store.dispatch("getDetailAssets", this.params.value);
    //   this.filterShow = false;
    // },
    async goToDetail() {
      this.$router.push(`/editDetailAsset/${this.params.value}`);
    },
  },
};
</script>

<style scoped>
.bin {
  margin-bottom: 13px;
}
</style>
