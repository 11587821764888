<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 300px"
      class="ag-theme-alpine my-3"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :setQuickFilter="updateSearchQuery"
      :gridOptions="gridOptions"
      @first-data-rendered="onFirstDataRendered"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEdit from "../Table/components/iconEdit.vue";
import btnConfirmlost from "../Table/components/btnConfirmlost.vue";
import moment from "moment";
export default {
  components: {
    AgGridVue,
    "table-iconEdit": iconEdit,
    btnConfirmlost,
  },
  data() {
    return {
      isSelectedAsset: false,
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
    };
  },
  props: {
    dataTable: [],
    searchText: {
      type: String,
    },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("maintenance_plan.maintenance_date"),
        field: "maintenance_date",
        minWidth: 150,
        // suppressSizeToFit: true,
      },
      {
        headerName: this.$t("maintenance_plan.maintenance_detail"),
        field: "maintenance_detail",
        minWidth: 150,
        // suppressSizeToFit: true,
      },
      {
        headerName: this.$t("maintenance_plan.maintenance_price"),
        field: "maintenance_price",
        minWidth: 150,
        // suppressSizeToFit: true,
      },
    ];
  },
  computed: {
    rowData() {
      return this.dataTable.map((data) => {
        return {
          maintenance_date: this.momentDay(data.repair_date),
          maintenance_detail: data.detail || "-",
          maintenance_price: this.numberComma(data.repair_cost),
        };
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onSelectionChanged() {
      this.isSelectedAsset =
        this.gridApi.getSelectedNodes().length !== 0 ? true : false;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.dataTable.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    numberComma(num) {
      let number = num;
      if (number) {
        number = number.toFixed(2);
      }
      return number
        ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        : "-";
    },
  },
  updated() {
    this.onSelectionChanged();
  },
};
</script>

<style scoped>
/* * >>> .ag-header-cell-label {
  justify-content: center;
} */
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
