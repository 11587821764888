<template>
  <div v-if="isShow">
    <img
      v-if="params.value"
      style="cursor: pointer;"
      @click="showDelete(params.value)"
      src="@/assets/delete_bin.svg"
    />
    <img class="disabledBin" v-else src="@/assets/delete_bin_disabled.svg" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserRoles } from "@/constants/UserRoles";
export default {
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      mainAssets: "category",
      isRoleLower: "isRoleLower",
    }),
    isShow() {
      if (this.params.value == "hide") {
        return false;
      }
      if (this.isRoleLower) {
        return false;
      }
      if (this.params.data.edit.is_payer) {
        return false;
      }
      let roleUser = this.permissionStatus.permission.role.id_role;
      let roleData = this.params.data.edit.permission.role.id_role;
      if (roleUser === UserRoles.ACC_MANAGER || roleUser < roleData) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async showDelete(user) {
      // const id_user = await this.$store.dispatch("getCurrentIdUser");
      // this.$parent.$parent.$parent.goToDetail(user.id_user);
      this.$parent.$parent.$parent.showDeleteUserModal(user);
    },
  },
};
</script>

<style scoped></style>
