<template>
  <div :style="{ height: btnHeight }" v-if="hasCustom">
    <button
      v-if="permissionStatus.company.custom_id === 1"
      class="tmp-button"
      :class="{ active: checkString }"
      @click="setSearchTerm"
    >
      <p>TMP</p>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["searchTerm"],
  emits: ["set-search-term"],
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
    checkString() {
      if (this.searchTerm.length >= 3) {
        let firstThree = this.searchTerm.substring(0, 3).toLowerCase();
        return firstThree.toLowerCase().includes("tmp");
      }
      return false;
    },
    btnHeight() {
      if (
        this.$route.path === "/admin/ManageAsset" ||
        this.$route.path === "/all_asset" ||
        this.$route.path === "/fixed_assets" ||
        this.$route.path.includes("/dashboard")
      ) {
        return "auto";
      } else {
        return "100%";
      }
    },
    hasCustom() {
      return (
        this.permissionStatus.company?.custom_id > 0 &&
        this.permissionStatus.company?.custom_id != null
      );
    },
  },
  methods: {
    setSearchTerm() {
      if (this.checkString) {
        if (this.searchTerm) {
          this.$emit("set-search-term", this.searchTerm.substring(3));
        }
      } else {
        if (this.searchTerm) {
          this.$emit("set-search-term", `TMP${this.searchTerm}`);
        } else {
          this.$emit("set-search-term", "TMP");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tmp-button {
  background-color: #fff;
  padding: 0px 15px;
  height: 100%;
  max-height: 45px;
  border: 2px solid #7c7c7c;
  border-radius: 10px;
  margin-right: 10px;
  height: 100%;
  p {
    margin: 0;
    color: #7c7c7c;
  }

  &.active {
    background-color: #006ffe;
    border: 2px solid transparent;
    p {
      color: #fff;
    }
  }
}
</style>
