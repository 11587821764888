<template>
  <div>
    <!-- {{mainAsset}} -->
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Cookie from "js-cookie";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../Table/components/iconEditCheck.vue";
import iconDetailSubAsset from "../Table/components/iconDetailSubAsset.vue";
import btnConfirmUser from "../Table/components/btnConfirmUser.vue";
import iconDeleteCheck from "../Table/components/iconDeleteCheck.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AgGridVue,
    iconEditCheck,
    btnConfirmUser,
    iconDeleteCheck,
    iconDetailSubAsset,
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  props: ["page", "perPage"],

  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        minWidth: 110,
      },
      {
        headerName: this.$t("category.main_asset"),
        field: "mainasset",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("category.count_subasset"),
        field: "count",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("category.sub_asset"),
        field: "subasset",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("category.manage_subasset"),
        field: "seedetail",
        cellRendererFramework: "iconDetailSubAsset",
        // sortable: true,
        minWidth: 200,
      },

      {
        headerName: this.$t("category.edit_name"),
        field: "edit",
        cellRendererFramework: "iconEditCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
      // {
      //   headerName: this.$t('home.conf_us'),
      //   field: "confirm",
      //   cellRendererFramework: "btnConfirmUser",
      //   maxWidth: 170,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: this.$t("category.delete"),
        field: "delete",
        cellRendererFramework: "iconDeleteCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
    ];
  },
  computed: {
    ...mapGetters({
      mainAssets: "category",
    }),

    rowData() {
      // console.log(this.mainAsset, "dataTable");
      return this.mainAssets.map((mainAssets, index) => {
        // if(user.user.email ===)
        // console.log(user);
        return {
          index: (this.page - 1) * this.perPage + index + 1,
          mainasset: mainAssets.name,
          count: mainAssets.total_sub_categories,
          subasset: mainAssets.list_sub_categories
            ? mainAssets.list_sub_categories
            : "-",
          edit: { id_user: mainAssets.id_category },
          delete: mainAssets,
          seedetail: mainAssets.id_category,
        };
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  async mounted() {
    let cookie = Cookie.get("cookielogin");
    if (cookie) {
      let cookiejson = JSON.parse(cookie);
      this.id_user = cookiejson.id_user;
    }
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
