import { Decimal } from "decimal.js";
export const toShowAccountingPrice = (num) => {
  if ((num && !isNaN(num)) || num === 0) {
    if (num < 0) {
      const invertNum = -num;
      const x = new Decimal(invertNum);
      const convNum = +x.toFixed(2, Decimal.ROUND_DOWN);
      return `(${(+convNum).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })})`;
    } else {
      const x = new Decimal(num);
      const convNum = +x.toFixed(2, Decimal.ROUND_DOWN);
      return (+convNum).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  } else {
    return "-";
  }
};
