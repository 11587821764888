export const UserRoles = Object.freeze({
  EXCLUSIVE: 1,
  ACC_MANAGER: 2,
  ACC_OFFICER: 3,
  DEPT_MANAGER: 4,
  OFFICER: 5,
  TECHNICIAN: 6,
  WAREHOUSE_OFFICER: 7,
  LOW_TECHNICIAN: 8,
});
